import * as React from "react";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText, ContentfulImage } from "@src/components";

export type ProductImageBlockProps = {
  image?: IContentfulImage | null;
  caption?: Document;
  orientation?: string | null;
  mobileImage?: IContentfulImage | null;
};

export function ProductImageBlock({
  image,
  caption,
  orientation = "contained",
  mobileImage
}: ProductImageBlockProps) {
  if (!image) {
    return null;
  }

  const dimensions = {
    width: image.width || 1602,
    height: image.height || 990
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div>
      <ProductContainer>
        <div
          className={`mx-auto space-y-6 lg:space-y-8 ${
            orientation == "contained" ? "max-w-[800px]" : "max-w-[1168px]"
          }`}
        >
          <figure
            className={` ${
              mobileImage ? "hidden lg:block" : "block"
            } relative space-y-1 md:space-y-4`}
          >
            <ContentfulImage
              image={image}
              transforms={transforms}
              className="rounded-2xl md:rounded-3xl"
            />
            {caption && caption.content.length > 0 && (
              <figcaption className="type-pr-eta text-pr-dark-gray-400">
                <RichText text={caption} />
              </figcaption>
            )}
          </figure>

          {mobileImage && (
            <figure className="relative space-y-1 md:space-y-4 lg:hidden">
              <img
                src={mobileImage.url || ""}
                alt={mobileImage.description || ""}
              ></img>
              {caption && caption.content.length > 0 && (
                <figcaption className="type-pr-eta text-pr-dark-gray-400">
                  <RichText text={caption} />
                </figcaption>
              )}
            </figure>
          )}
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductImageBlock;
