import {
  DTRDynamicSectionSwiperCollectionItems,
  DynamicTrendReportDynamicSectionsCollection,
  DynamicTrendReportDynamicSectionsCollectionItems
} from "@src/lib/services/server/contentful";

import { DtrDynamicSwiper } from "@src/components";

import * as React from "react";

export type DtrDynamicSectionsProps = {
  content: DynamicTrendReportDynamicSectionsCollection;
};

const DtrDynamicSections = ({ content }: DtrDynamicSectionsProps) => {
  const { items: dynamicSectionItems } = content as {
    items: DynamicTrendReportDynamicSectionsCollectionItems[];
  };

  return (
    <>
      {dynamicSectionItems.map(
        ({ title, description, swiperSlidesCollection }, index) => {
          const id = title?.toLowerCase().split(" ").join("-") ?? "";
          return (
            <div
              key={title}
              id={id}
              className={`relative mx-auto flex w-full flex-col gap-6 text-balance px-5 py-16 text-center lg:px-8 2xl:px-24 ${
                index % 2 === 0 ? "bg-pr-yellow-200" : "bg-white"
              }`}
            >
              <div className="mx-auto w-full max-w-screen-2xl">
                <div className="mb-6 flex flex-col gap-6">
                  <h2 className="text-5xl font-bold text-pr-dark-gray-600">
                    {title}
                  </h2>
                  <p>{description}</p>
                </div>
                <DtrDynamicSwiper
                  id={id}
                  content={
                    swiperSlidesCollection as DTRDynamicSectionSwiperCollectionItems
                  }
                />
              </div>
            </div>
          );
        }
      )}
    </>
  );
};

export { DtrDynamicSections };
