import {
  ContentModule,
  GlobalProps
} from "@src/lib/services/server/contentful";
import * as React from "react";
import {
  BlogContainer,
  BlogLayout,
  ContentModules,
  ProductContentModules,
  ProductLayout
} from "@src/components";
import Head from "next/head";
import { type BlogNavigationQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { TrendReportContentModules } from "../TrendReportsContentModules";

export type ModuleLibraryProps = GlobalProps & {
  type?: "product" | "blog";
  contentModules: ContentModule[];
  blogNavigation?: BlogNavigationQuery;
  availableContentModules: Record<string, string[] | undefined>;
};

function ProductWrapper({ contentModule }: { contentModule: ContentModule }) {
  return (
    <section>
      <div>
        <ProductContentModules contentModules={[contentModule]} />
      </div>
    </section>
  );
}

function BlogWrapper({ contentModule }: { contentModule: ContentModule }) {
  return (
    <section>
      <BlogContainer>
        <div className="grid grid-cols-12 gap-5 lg:gap-10">
          <aside className="col-span-full mt-8 md:col-span-3 md:mt-0 md:py-16 xl:col-span-2">
            <div className="space-y-8"></div>
          </aside>

          <div className="col-span-full md:col-span-9 xl:col-span-8">
            <ContentModules contentModules={[contentModule]} />
          </div>

          <div className="col-span-full pb-8 md:hidden"></div>
        </div>
      </BlogContainer>
    </section>
  );
}

export function ModuleLibrary({
  type = "product",
  contentModules,
  availableContentModules,
  ...layoutProps
}: ModuleLibraryProps) {
  const Layout = type === "blog" ? BlogLayout : ProductLayout;
  const Wrapper = type === "blog" ? BlogWrapper : ProductWrapper;
  return (
    <Layout {...layoutProps}>
      <Head>
        <title>Module Library</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      {contentModules.map(contentModule => (
        <>
          <div className="border-y-2 border-dotted bg-slate-50 py-6 text-left font-mono text-black">
            <div
              className={`mx-auto w-full ${
                type === "blog" ? "max-w-3xl" : "max-w-7xl"
              }`}
            >
              <div className="grid grid-cols-2 gap-4">
                <div className="text-xl">
                  {contentModule.__typename
                    .replace("ProductCm", "Product CM > ")
                    .replace(/^Cm/, "CM > ")}
                </div>
                <div className="text-sm">
                  {availableContentModules[
                    contentModule.__typename.charAt(0).toLowerCase() +
                      contentModule.__typename.slice(1)
                  ]?.join(", ")}
                  {/* Available Content Types:
                  <ul className="list-disc pl-4">
                    {availableContentModules[
                      contentModule.__typename.charAt(0).toLowerCase() +
                        contentModule.__typename.slice(1)
                    ]?.map((name, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
          {contentModule.__typename != "TrendReport1ColumnText" &&
          contentModule.__typename != "TrendReportColumnCards" &&
          contentModule.__typename != "TrendReport2ColumnImageWithText" ? (
            <Wrapper contentModule={contentModule} />
          ) : (
            <section>
              <div>
                <TrendReportContentModules
                  contentModules={[contentModule]}
                  hasBackground={null}
                />
              </div>
            </section>
          )}
        </>
      ))}
    </Layout>
  );
}
