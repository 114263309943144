import { DynamicTrendReportHeroSection } from "@src/lib/services/server/contentful";

import * as React from "react";
import Image from "next/image";

export type DtrHeroSectionProps = {
  content: DynamicTrendReportHeroSection;
};

const DtrHeroSection = ({ content }: DtrHeroSectionProps) => {
  const {
    title: heroTitle,
    description: heroDescription,
    image: heroImage
  } = content;

  return (
    <div className="relative mx-auto my-16 flex w-full max-w-screen-2xl flex-col gap-6 px-5 text-center lg:px-8 2xl:px-24">
      <Image
        src={heroImage?.url ?? ""}
        alt={heroImage?.title ?? ""}
        width="899"
        height="500"
        className="rounded-3xl"
      />
      <h1 className="text-6xl font-bold text-pr-dark-gray-600">{heroTitle}</h1>
      <p>{heroDescription}</p>
    </div>
  );
};

export { DtrHeroSection };
