import * as React from "react";
import { Pillar } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import {
  ProductContainer,
  SmartLink,
  ContentfulImage,
  RichText
} from "@src/components";

export type ProductPillarsBlockProps = {
  eyebrow?: string | null;
  heading?: string | null;
  text?: Document;
  items: Pillar[];
  orientation?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductPillarsBlock({
  eyebrow,
  heading,
  text,
  items,
  orientation
}: ProductPillarsBlockProps) {
  // const dimensions = {
  //   width: 180,
  //   height: 180,
  // };

  // const transforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: dimensions.width,
  //   height: dimensions.height,
  // };
  const defaultOrientation: string = "2columns";

  const orientationClassNames: { [key: string]: string } = {
    "2columns": "md:grid-cols-2 md:gap-7 lg:gap-8",
    "3columns": "md:grid-cols-3 md:gap-7 lg:gap-12",
    "4columns": "md:grid-cols-4 md:gap-7 lg:gap-8"
  };

  const orientationClassName =
    orientationClassNames[orientation ? orientation : defaultOrientation];

  const orientationPaddingClassNames: { [key: string]: string } = {
    "2columns": "lg:px-16 lg:py-20",
    "3columns": "lg:px-6 lg:pb-24 lg:pt-[108px]",
    "4columns": "lg:px-6 lg:pb-10 lg:pt-10"
  };

  const orientationPaddingClassName =
    orientationPaddingClassNames[
      orientation ? orientation : defaultOrientation
    ];

  const renderItems = (items: Pillar[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          href={contentfulEntryUrl(item)}
          className={`group rounded-3xl bg-pr-yellow-200 px-3.5 py-5 transition hover:drop-shadow-md focus:drop-shadow-md md:rounded-2xl ${orientationPaddingClassName}
          `}
          key={index}
        >
          <div className="flex items-center justify-center gap-2 md:block md:space-y-7">
            {item.icon && (
              <figure
                className={`relative mx-auto size-24 shrink-0 ${
                  orientation == "4columns" ? "md:size-20" : "md:size-16"
                }`}
              >
                <ContentfulImage
                  image={item.icon}
                  layout="fill"
                  className="object-contain"
                />
              </figure>
            )}
            <div className="space-y-1 md:space-y-5">
              <h3
                className={`link-underline-animated-lg flex w-fit items-center gap-2 text-pr-2xl font-bold tracking-[-0.735px] md:mx-auto md:block ${
                  orientation == "4columns"
                    ? "md:type-pr-delta"
                    : "md:type-pr-gamma"
                }`}
              >
                {item.title}
                <svg
                  className="md:hidden"
                  width="16"
                  height="10"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3536 4.35355C13.5488 4.15829 13.5488 3.84171 13.3536 3.64645L10.1716 0.464466C9.97631 0.269204 9.65973 0.269204 9.46447 0.464466C9.2692 0.659728 9.2692 0.976311 9.46447 1.17157L12.2929 4L9.46447 6.82843C9.2692 7.02369 9.2692 7.34027 9.46447 7.53553C9.65973 7.7308 9.97631 7.7308 10.1716 7.53553L13.3536 4.35355ZM0 4.5H13V3.5H0V4.5Z"
                    fill="black"
                  />
                </svg>
              </h3>
              <div
                className={`type-pr-eta md:type-pr-theta pr-5 text-left text-pr-dark-gray-400 md:pr-0 md:text-center ${
                  orientation == "2columns" ? "" : "mx-auto max-w-[370px]"
                }`}
              >
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        </SmartLink>
      );
    });
  };

  return (
    <div>
      <ProductContainer>
        <div className={`${heading || text ? "space-y-10 lg:space-y-20" : ""}`}>
          <div className="mx-auto max-w-[902px] space-y-2.5 text-center lg:space-y-4">
            {eyebrow && (
              <span className="type-pr-theta md:type-pr-eta hidden text-pr-dark-gray-400 md:block">
                {eyebrow}
              </span>
            )}
            <div className="space-y-4 px-5 md:px-0 lg:space-y-6">
              {heading && (
                <h2
                  className={`${
                    orientation == "2columns"
                      ? "type-pr-beta xl:text-pr-12xl"
                      : "type-pr-delta md:type-pr-beta font-bold"
                  }`}
                >
                  {returnLineBreaks(heading)}
                </h2>
              )}
              {text && (
                <div className="type-pr-eta md:type-pr-zeta  mx-auto max-w-[686px] leading-pr-looser">
                  <RichText text={text} />
                </div>
              )}
            </div>
          </div>
          <div
            className={`mx-auto grid grid-cols-1 gap-6 text-center ${orientationClassName} `}
          >
            {renderItems(items)}
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductPillarsBlock;
