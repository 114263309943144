/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { MutableRefObject, useEffect, useState } from "react";
import Headroom from "react-headroom";
import { Popover, Disclosure, Transition } from "@headlessui/react";
import type {
  ProductNavigation2Query,
  ProductNavigation3Query,
  ProductNavigationQuery
} from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { Document } from "@contentful/rich-text-types";
import {
  ProductContainer,
  SmartLink,
  ContentfulImage,
  RichText
} from "@src/components";
import {
  contentfulEntryUrl,
  ContentfulEntryWithUrl,
  filterNull
} from "@src/lib/utils";
import {
  ProductNavigationGroup,
  ProductNavigationLink,
  ProductNavigationGroupLinksCollectionItem,
  Alert,
  Service,
  ProductNavigationCta,
  ProductPillarNavigation,
  ProductPillarSubNavigation,
  ProductPillarSubNavigationLinksCollectionItem,
  ProductPillarNavigation2
} from "@src/lib/services/server/contentful";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { IContentfulImage } from "@src/lib/types";
import Chevron from "@/img/icons/chevron.svg";
import Logo from "@/img/icons/logo.svg";
import Menu from "@/img/icons/menu.svg";
import Close from "@/img/icons/close.svg";
import { Pillar } from "@src/pages";

export type ProductNavigationProps = {
  navigation: ProductNavigationQuery;
  navigation2?: ProductNavigation2Query;
  navigation3?: ProductNavigation3Query;
  navigationStyle?: string | null;
  pageType?: string | null;
};

export type ProductSubLinkProps = {
  title?: string | null;
  slug: ContentfulEntryWithUrl;
};

export type CombinedPillar = ProductPillarNavigation & ProductPillarNavigation2;

export function ProductNavigation({
  navigation,
  navigation2,
  navigation3,
  navigationStyle = "yellow",
  pageType
}: ProductNavigationProps) {
  const resize = () => {
    const resizeEvent = new Event("resize");
    window.dispatchEvent(resizeEvent);
    setResized(true);
  };

  const cookies = parseCookies();
  const [alertActive, setAlertActive] = useState(true);
  const [homepageAlertActive, setHomepageAlertActive] = useState(true);
  const [blogAlertActive, setBlogAlertActive] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [megaMenuActive, setMegaMenuActive] = useState(false);
  const [headroomPinned, setHeadroomPinned] = useState(true);
  const [resized, setResized] = useState(false);
  const [isHovered, setIsHovered] = useState<boolean[]>([false]);
  const [isPillarHovered, setIsPillarHovered] = useState(false);

  const handleMouseEnter = (index: number) => {
    const items = [...isHovered];
    items[index] = true;
    setIsHovered(items);
  };

  const handleMouseLeave = (index: number) => {
    const items = [...isHovered];
    items[index] = false;
    setIsHovered([false]);
  };

  const handlePillarMouseEnter = () => {
    setIsPillarHovered(true);
  };

  const handlePillarMouseLeave = () => {
    setIsPillarHovered(false);
  };

  const dismissAlert = (alertType: string | null | undefined) => {
    if (alertType === "Homepage") {
      setCookie(null, "homepage-alert-dismissed", "true", {
        maxAge: 60 * 60 * 24
      });
      setHomepageAlertActive(false);
    } else if (alertType === "Blog") {
      setCookie(null, "blog-alert-dismissed", "true", {
        maxAge: 60 * 60 * 24
      });
      setBlogAlertActive(false);
    } else {
      setCookie(null, "alert-dismissed", "true", {
        maxAge: 60 * 60 * 24
      });
      setAlertActive(false);
    }
  };

  useEffect(() => {
    if (!resized) {
      resize();
    }

    if ("alert-dismissed" in cookies) {
      setAlertActive(false);
    }

    if ("homepage-alert-dismissed" in cookies) {
      setHomepageAlertActive(false);
    }

    if ("blog-alert-dismissed" in cookies) {
      setBlogAlertActive(false);
    }

    const body = document.querySelector("body");
    if (body) {
      mobileMenuActive
        ? body.classList.add("headroom--product-menu-active")
        : body.classList.remove("headroom--product-menu-active");
    }

    const handleScroll = () => {
      if (megaMenuActive && !headroomPinned) {
        const popoverActive: HTMLElement | null =
          document.querySelector(".popover-active");
        if (popoverActive) {
          popoverActive.click();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [resized, setAlertActive, cookies, mobileMenuActive, headroomPinned]);

  const navigationItem = navigation.productNavigationCollection
    ? navigation.productNavigationCollection.items[0]
    : null;

  const navigationItem2 = navigation2?.productNavigationCollection
    ? navigation2.productNavigationCollection.items[0]
    : null;

  const navigationItem3 = navigation3?.productNavigationCollection
    ? navigation3.productNavigationCollection.items[0]
    : null;

  if (!navigationItem) {
    return null;
  }

  const alertClasses: { [key: string]: string } = {
    red: "bg-pr-red-500",
    teal: "bg-pr-teal-600",
    yellow: "bg-[#FFC341]",
    black: "bg-pr-dark-gray-500",
    darkred: "bg-pr-red-900",
    white: "bg-white"
  };

  const borderClassNames: { [key: string]: string } = {
    red: "border-pr-red-500",
    green: "border-pr-green-500",
    teal: "border-pr-teal-600",
    yellow: "border-[#FFC341]",
    black: "border-pr-dark-gray-500",
    darkred: "border-pr-red-900",
    white: "border-white"
  };

  const ctaClassNames: { [key: string]: string } = {
    red: "text-pr-red-500 font-black underline underline-offset-4",
    green: "text-pr-green-500 link-underline-animated-sm ",
    yellow: "text-pr-yellow-500 link-underline-animated-sm ",
    teal: "text-pr-teal-500 link-underline-animated-sm ",
    black: "text-pr-dark-gray-500 link-underline-animated-sm "
  };

  const textClassNames: { [key: string]: string } = {
    white: "text-white",
    black: "text-pr-dark-gray-500"
  };

  const defaultAlertStyle: string = "darkRed";
  const defaultBorderColor: string = "green";
  const defaultCtaStyle: string = "yellow";
  const defaultTextStyle: string = "white";

  let alert = navigationItem.alert as Alert | null;
  let alertStyle = navigationItem.alert?.alertStyle
    ?.toLowerCase()
    .split(" ")
    .join("") as string;
  let alertType = "Product";

  let ctaStyle = navigationItem.alert?.ctaStyle
    ?.toLowerCase()
    .split(" ")
    .join("") as string;

  let textStyle = navigationItem.alert?.textStyle
    ?.toLowerCase()
    .split(" ")
    .join("") as string;

  let borderColor = navigationItem.alert?.borderColor
    ?.toLowerCase()
    .split(" ")
    .join("") as string;

  if (pageType == "Homepage" && navigationItem.homepageAlert) {
    alert = navigationItem.homepageAlert as Alert | null;
    console.log(alert?.icon);
    alertStyle = navigationItem.homepageAlert.alertStyle
      ?.toLowerCase()
      .split(" ")
      .join("") as string;
    alertType = pageType;

    ctaStyle = navigationItem.homepageAlert.ctaStyle
      ?.toLowerCase()
      .split(" ")
      .join("") as string;

    textStyle = navigationItem.homepageAlert.textStyle
      ?.toLowerCase()
      .split(" ")
      .join("") as string;

    borderColor = navigationItem.homepageAlert.borderColor
      ?.toLowerCase()
      .split(" ")
      .join("") as string;
  }

  if (pageType == "Blog" && navigationItem.blogAlert) {
    alert = navigationItem.blogAlert as Alert | null;
    alertStyle = navigationItem.blogAlert.alertStyle
      ?.toLowerCase()
      .split(" ")
      .join("") as string;
    alertType = pageType;

    ctaStyle = navigationItem.blogAlert.ctaStyle
      ?.toLowerCase()
      .split(" ")
      .join("") as string;

    textStyle = navigationItem.blogAlert.textStyle
      ?.toLowerCase()
      .split(" ")
      .join("") as string;

    borderColor = navigationItem.blogAlert.borderColor
      ?.toLowerCase()
      .split(" ")
      .join("") as string;
  }

  const alertClass = alertClasses[alertStyle ? alertStyle : defaultAlertStyle];
  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];
  const textClassName =
    textClassNames[textStyle ? textStyle : defaultTextStyle];
  const borderClassName =
    borderClassNames[borderColor ? borderColor : defaultBorderColor];

  const renderAlertHeader = (
    alert: Alert | null,
    alertActive: boolean,
    alertType: string | null | undefined
  ) => {
    return (
      <div>
        {alert && alertActive && (
          <div
            className={`headroom--alert alert-animation flex w-full overflow-hidden border-b-2 py-4 ${borderClassName} ${alertClass} ${textClassName}`}
          >
            <ProductContainer>
              <div className="flex items-center justify-between gap-3">
                <div className="flex w-full flex-col items-center justify-center gap-3 md:flex-row md:gap-4 md:text-xl">
                  {alert.icon && (
                    <figure className="relative h-[34px] w-[31px] shrink-0">
                      <ContentfulImage
                        image={alert.icon as IContentfulImage}
                        layout="fill"
                        className="object-contain"
                        priority={true}
                      />
                    </figure>
                  )}

                  {alert?.heading && !alert?.richHeading?.json && (
                    <>{returnLineBreaks(alert.heading)}</>
                  )}

                  {alert?.richHeading?.json && (
                    <RichText text={alert.richHeading.json as Document} />
                  )}
                  {alert.ctaUrl && alert.ctaTitle && (
                    <SmartLink
                      href={alert.ctaUrl}
                      className={`${ctaClassName}`}
                    >
                      {alert.ctaTitle}
                    </SmartLink>
                  )}
                </div>
                <button
                  // onClick={() => setAlertActive((alertActive) => !alertActive)}
                  onClick={() => dismissAlert(alertType)}
                  className="absolute right-4"
                >
                  <Close className="size-4 stroke-current" />
                </button>
              </div>
            </ProductContainer>
          </div>
        )}
      </div>
    );
  };

  const renderAlert = (alertType: string | null | undefined) => {
    switch (alertType) {
      case "Homepage":
        return renderAlertHeader(alert, homepageAlertActive, alertType);
      case "Blog":
        return renderAlertHeader(alert, blogAlertActive, alertType);
      default:
        return renderAlertHeader(alert, alertActive, alertType);
    }
  };

  const navigationElements = filterNull(
    navigationItem.navigationElementsCollection?.items
  ) as ProductNavigationGroup[] & ProductNavigationLink[] & CombinedPillar[];

  const navigationElements2 = filterNull(
    navigationItem2?.navigationElementsCollection?.items
  ) as ProductNavigationGroup[] & ProductNavigationLink[] & CombinedPillar[];

  const navigationElements3 = filterNull(
    navigationItem3?.navigationElementsCollection?.items
  ) as ProductNavigationGroup[] & ProductNavigationLink[] & CombinedPillar[];

  type ContentModuleItem = ProductNavigationGroup[] &
    ProductNavigationLink[] &
    CombinedPillar[] &
    undefined;

  const combinedNavigationElements = navigationElements.map(
    (contentModule, count) => {
      if ("sys" in contentModule) {
        return contentModule;
      } else {
        return {
          ...(navigationElements2[count] as CombinedPillar[]),
          ...(navigationElements3[count] as CombinedPillar[])
        } as ContentModuleItem;
      }
    }
  );

  const navigationCtas = filterNull(
    navigationItem.navigationCtasCollection?.items
  ) as ProductNavigationCta[] | undefined;

  const returnLineBreaks = (text: string) => {
    if (text.includes("\n")) {
      return text.split("\n").map((innerText: string, i: number) => {
        return (
          <span key={i} className="block">
            {innerText}
          </span>
        );
      });
    } else {
      return text;
    }
  };

  const renderNavigationElementsMobile = (
    items:
      | ProductNavigationGroup[]
      | ProductNavigationLink[]
      | CombinedPillar[],
    close: (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined
    ) => void
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationLink":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          const phonenumber: string | undefined = isPhoneNumber
            ? item.url?.replace(/[^\d]/g, "")
            : "";
          return (
            <li key={index} className="border-b border-[#D9D9D9] p-[22px]">
              <SmartLink
                href={
                  isPhoneNumber
                    ? `tel:+${phonenumber as string}`
                    : (item.url as string)
                }
                onClick={() => {
                  close();
                }}
              >
                <span className="type-pr-delta link-underline-animated-sm font-bold">
                  {item.title}
                </span>
              </SmartLink>
            </li>
          );
        case "ProductNavigationGroup":
          const label = item.label;
          const linksCollection = item.linksCollection;
          const items = linksCollection?.items as
            | ProductNavigationGroupLinksCollectionItem[]
            | undefined;

          return (
            <li key={index} className="border-b border-[#D9D9D9]">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`group relative w-full  px-[22px] pt-6 text-left ${
                        open ? " sticky top-0 z-20 w-full bg-pr-yellow-100" : ""
                      } `}
                    >
                      {label && (
                        <div className="flex items-center justify-between pb-6">
                          <span className="type-pr-delta font-bold">
                            {label}
                          </span>
                          <svg
                            className={`${open ? "rotate-180" : ""}`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="12"
                            viewBox="0 0 20 12"
                            fill="none"
                          >
                            <path
                              d="M19 1L9.93431 10L1 1"
                              stroke="#30323B"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      )}
                    </Disclosure.Button>
                    <Transition className="-mx-5 overflow-hidden bg-white px-[42px] py-4">
                      <Disclosure.Panel as="ul">
                        {items && renderNavigationLinksMobile(items, close)}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </li>
          );
        case "ProductPillarNavigation":
          const labelPillar = item.label;
          const linksCollectionPillar = item.pillarOneSubnavigationCollection;
          const itemsPillar = linksCollectionPillar?.items as
            | ProductPillarSubNavigation[]
            | undefined;
          const linksCollectionPillarTwo =
            item.pillarTwoSubnavigationCollection;
          const itemsPillarTwo = linksCollectionPillarTwo?.items as
            | ProductPillarSubNavigation[]
            | undefined;
          const linksCollectionPillarThree =
            item.pillarThreeSubnavigationCollection;
          const itemsPillarThree = linksCollectionPillarThree?.items as
            | ProductPillarSubNavigation[]
            | undefined;
          const linksCollectionPillarFour =
            item.pillarFourSubnavigationCollection;
          const itemsPillarFour = linksCollectionPillarFour?.items as
            | ProductPillarSubNavigation[]
            | undefined;
          return (
            <li key={index} className="border-b border-[#D9D9D9]">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`group relative w-full px-[22px] pt-6 text-left ${
                        open ? " sticky top-0 z-20 w-full bg-pr-yellow-100" : ""
                      } `}
                    >
                      {labelPillar && (
                        <div className="flex items-center justify-between pb-6">
                          <span className="type-pr-delta font-bold">
                            {labelPillar}
                          </span>
                          <svg
                            className={`${open ? "rotate-180" : ""}`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="12"
                            viewBox="0 0 20 12"
                            fill="none"
                          >
                            <path
                              d="M19 1L9.93431 10L1 1"
                              stroke="#30323B"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      )}
                    </Disclosure.Button>
                    <Transition className="-mx-5 overflow-hidden bg-white py-4 pl-[52px] pr-[50px]">
                      <Disclosure.Panel as="ul" className="space-y-4">
                        <div className="flex flex-row items-baseline gap-2">
                          {item.pillarOne?.icon && (
                            <figure className="relative size-4 shrink-0 self-start pt-5">
                              <ContentfulImage
                                image={item.pillarOne.icon as IContentfulImage}
                                layout="fill"
                                className="object-contain"
                                priority={true}
                              />
                            </figure>
                          )}
                          <div className="w-full text-left">
                            <div className="w-full border-b border-[#D9D9D9] pb-4">
                              <SmartLink
                                href={contentfulEntryUrl(
                                  item.pillarOne?.slug as ContentfulEntryWithUrl
                                )}
                                className="group"
                                onClick={() => {
                                  close();
                                }}
                              >
                                <span className="text-pr-lg font-medium">
                                  {item.pillarOne?.title}
                                </span>
                              </SmartLink>
                            </div>
                            {itemsPillar && itemsPillar.length > 0 && (
                              <span className="flex flex-col">
                                {itemsPillar &&
                                  renderPillarNavigationMobile(
                                    itemsPillar,
                                    close
                                  )}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row items-baseline gap-2">
                          {item.pillarTwo?.icon && (
                            <figure className="relative size-4 shrink-0 self-start pt-5">
                              <ContentfulImage
                                image={item.pillarTwo.icon as IContentfulImage}
                                layout="fill"
                                className="object-contain"
                                priority={true}
                              />
                            </figure>
                          )}
                          <div className="w-full text-left">
                            <div className="w-full border-b border-[#D9D9D9] pb-4">
                              <SmartLink
                                href={contentfulEntryUrl(
                                  item.pillarTwo?.slug as ContentfulEntryWithUrl
                                )}
                                className="group"
                                onClick={() => {
                                  close();
                                }}
                              >
                                <span className="text-pr-lg font-medium">
                                  {item.pillarTwo?.title}
                                </span>
                              </SmartLink>
                            </div>
                            {itemsPillarTwo && itemsPillarTwo.length > 0 && (
                              <span className="flex flex-col">
                                {itemsPillarTwo &&
                                  renderPillarNavigationMobile(
                                    itemsPillarTwo,
                                    close
                                  )}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row items-baseline gap-2">
                          {item.pillarThree?.icon && (
                            <figure className="relative size-4 shrink-0 self-start pt-5">
                              <ContentfulImage
                                image={
                                  item.pillarThree.icon as IContentfulImage
                                }
                                layout="fill"
                                className="object-contain"
                                priority={true}
                              />
                            </figure>
                          )}
                          <div className="w-full text-left">
                            <div className="w-full border-b border-[#D9D9D9] pb-4">
                              <SmartLink
                                href={contentfulEntryUrl(
                                  item.pillarThree
                                    ?.slug as ContentfulEntryWithUrl
                                )}
                                className="group"
                                onClick={() => {
                                  close();
                                }}
                              >
                                <span className="text-pr-lg font-medium">
                                  {item.pillarThree?.title}
                                </span>
                              </SmartLink>
                            </div>
                            {itemsPillarThree &&
                              itemsPillarThree.length > 0 && (
                                <span className="flex flex-col">
                                  {itemsPillarThree &&
                                    renderPillarNavigationMobile(
                                      itemsPillarThree,
                                      close
                                    )}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="flex flex-row items-baseline gap-2">
                          {item.pillarFour?.icon && (
                            <figure className="relative size-4 shrink-0 self-start pt-5">
                              <ContentfulImage
                                image={item.pillarFour.icon as IContentfulImage}
                                layout="fill"
                                className="object-contain"
                                priority={true}
                              />
                            </figure>
                          )}
                          <div className="w-full text-left">
                            <div className="w-full border-b border-[#D9D9D9] pb-4">
                              <SmartLink
                                href={contentfulEntryUrl(
                                  item.pillarFour
                                    ?.slug as ContentfulEntryWithUrl
                                )}
                                className="group"
                                onClick={() => {
                                  close();
                                }}
                              >
                                <span className="text-pr-lg font-medium">
                                  {item.pillarFour?.title}
                                </span>
                              </SmartLink>
                            </div>
                            {itemsPillarFour && itemsPillarFour.length > 0 && (
                              <span className="flex flex-col">
                                {itemsPillarFour &&
                                  renderPillarNavigationMobile(
                                    itemsPillarFour,
                                    close
                                  )}
                              </span>
                            )}
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </li>
          );
      }
    });
  };

  const renderNavigationCtasMobile = (
    items: ProductNavigationCta[],
    close: (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined
    ) => void
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationCta":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          const phonenumber: string | undefined = isPhoneNumber
            ? item.url?.replace(/[^\d]/g, "")
            : "";
          return (
            <div
              key={index}
              className={`mx-auto ${index == 1 ? "order-3" : ""}`}
            >
              {item.title && item.url && item.icon == null && (
                <SmartLink
                  href={
                    isPhoneNumber ? `tel:+${phonenumber as string}` : item.url
                  }
                  className={
                    "text-pr-dark-gray-500" +
                    (item.style == "Solid"
                      ? " pr-button pr-button-lg pr-button-teal mt-6 w-full !text-white"
                      : " link-underline-animated-sm text-[15px]") +
                    (index == 0
                      ? " border-b-[1.5px] border-[#D9D9D9] px-7 pb-4 font-bold"
                      : "")
                  }
                  onClick={() => {
                    close();
                  }}
                >
                  {item.title}
                </SmartLink>
              )}
              {item.icon && item.url && (
                <SmartLink
                  href={
                    isPhoneNumber ? `tel:+${phonenumber as string}` : item.url
                  }
                  className="pr-button w-full"
                  onClick={() => {
                    close();
                  }}
                >
                  <figure className="relative size-8 shrink-0">
                    <ContentfulImage
                      image={item.icon as IContentfulImage}
                      layout="fill"
                      className="object-contain"
                      priority={true}
                    />
                  </figure>
                </SmartLink>
              )}
            </div>
          );
      }
    });
  };

  const renderPillarNavigationMobile = (
    items: ProductPillarSubNavigation[] | ProductNavigationLink[],
    close: (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined
    ) => void
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationLink":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          const phonenumber: string | undefined = isPhoneNumber
            ? item.url?.replace(/[^\d]/g, "")
            : "";
          return (
            <li key={index} className="border-b border-[#D9D9D9] py-3.5 pl-5">
              <SmartLink
                href={
                  isPhoneNumber
                    ? `tel:+${phonenumber as string}`
                    : (item.url as string)
                }
                onClick={() => {
                  close();
                }}
                className="group"
              >
                <span className="text-[15px]">{item.title}</span>
              </SmartLink>
            </li>
          );
        case "ProductPillarSubNavigation":
          const subitems = item.linksCollection?.items as
            | ProductPillarSubNavigationLinksCollectionItem[]
            | undefined;
          return (
            <li key={index} className="border-b border-[#D9D9D9] py-4">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`group relative w-full pl-5 text-left`}
                    >
                      {item.title && (
                        <div className="flex w-full items-center justify-between">
                          <SmartLink
                            href={item.labelUrl || ""}
                            linkTag={true}
                            className="text-[15px]"
                          >
                            {item.title}
                          </SmartLink>

                          <svg
                            className={`${open ? "rotate-180" : ""}`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                          >
                            <path
                              d="M13 7L6.9562 1L1 7"
                              stroke="#30323B"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      )}
                    </Disclosure.Button>
                    <Transition className="overflow-hidden bg-white pt-4">
                      <Disclosure.Panel as="ul">
                        {subitems &&
                          renderSubNavigationPillarLinksMobile(subitems, close)}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </li>
          );
      }
    });
  };

  const renderNavigationLinksMobile = (
    items: ProductNavigationGroupLinksCollectionItem[],
    close: (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined
    ) => void
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationLink":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          const phonenumber: string | undefined = isPhoneNumber
            ? item.url?.replace(/[^\d]/g, "")
            : "";
          return (
            <li key={index} className="px-2">
              <div
                className={`py-3.5 ${
                  index == 0 ? "" : "border-t border-[#D9D9D9]"
                } `}
              >
                <SmartLink
                  href={
                    isPhoneNumber
                      ? `tel:+${phonenumber as string}`
                      : (item.url as string)
                  }
                  onClick={() => {
                    close();
                  }}
                  className="group"
                >
                  <span className="pl-5 text-[15px]">{item.title}</span>
                </SmartLink>
              </div>
            </li>
          );
        case "ProductSubNavigationGroup":
          const subitems = item.linksCollection?.items as
            | ProductPillarSubNavigationLinksCollectionItem[]
            | undefined;
          return (
            <li key={index} className="p-0 py-3">
              <div className="flex w-full flex-row gap-3">
                {item.icon && (
                  <figure className="relative mt-[3px] size-4 shrink-0 self-start">
                    <ContentfulImage
                      image={item.icon as IContentfulImage}
                      layout="fill"
                      className="object-contain"
                      priority={true}
                    />
                  </figure>
                )}
                <div className="w-full text-left">
                  <div className="border-b border-[#D9D9D9] pb-2.5">
                    <span className="text-xl font-medium">{item.title}</span>
                  </div>
                  <div>
                    {subitems && (
                      <span className="flex w-full flex-col">
                        {renderSubNavigationLinksMobile(subitems, close)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </li>
          );
        case "Pillar":
          const subItems = item.servicesCollection?.items as
            | ProductNavigationGroupLinksCollectionItem[];
          return (
            <li key={index}>
              <Disclosure>
                {({ open }) => (
                  <>
                    <div className="group relative w-full pr-5 text-left">
                      {item.title && (
                        <>
                          <SmartLink
                            href={contentfulEntryUrl(item)}
                            onClick={() => {
                              close();
                            }}
                            className="group inline-flex items-center gap-2.5 pb-1 md:pb-3"
                          >
                            {item.icon && (
                              <figure className="relative size-7 shrink-0 self-start">
                                <ContentfulImage
                                  image={item.icon as IContentfulImage}
                                  layout="fill"
                                  className="object-contain"
                                  priority={true}
                                />
                              </figure>
                            )}
                            <div>
                              <span className="link-underline-animated-sm text-pr-xl font-bold">
                                {item.title}
                              </span>
                            </div>
                          </SmartLink>
                          <Disclosure.Button className="">
                            <Chevron
                              className={`absolute right-0 top-2 h-2 w-2.5 stroke-current transition ${
                                open ? "rotate-180" : ""
                              }`}
                            />
                          </Disclosure.Button>
                        </>
                      )}
                    </div>
                    <Transition className="overflow-hidden py-4">
                      <Disclosure.Panel as="ul" className="space-y-4 pl-2">
                        {item.servicesCollection?.items &&
                          renderNavigationSubLinksMobile(subItems, close)}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </li>
          );
        default:
          return (
            <li key={index} className="px-2">
              <div
                className={`py-3.5 ${
                  index == 0 ? "" : "border-t border-[#D9D9D9]"
                } `}
              >
                <SmartLink
                  href={contentfulEntryUrl(item)}
                  className="group"
                  onClick={() => {
                    close();
                  }}
                >
                  <span className="pl-5 text-[15px]">{item.title}</span>
                </SmartLink>
              </div>
            </li>
          );
      }
    });
  };

  const renderNavigationSubLinksMobile = (
    items: ProductNavigationGroupLinksCollectionItem[],
    close: (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined
    ) => void
  ) => {
    return items.map((item, index) => {
      return (
        <li key={index}>
          <SmartLink
            href={contentfulEntryUrl(item)}
            className="group"
            onClick={() => {
              close();
            }}
          >
            <span className="type-pr-zeta text-[15px]">{item.title}</span>
          </SmartLink>
        </li>
      );
    });
  };

  const renderNavigationElementsDesktop = (
    items: ProductNavigationGroup[] | ProductNavigationLink[] | CombinedPillar[]
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationLink":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          const phonenumber: string | undefined = isPhoneNumber
            ? item.url?.replace(/[^\d]/g, "")
            : "";
          return (
            <li key={index} className="py-2">
              <SmartLink
                href={
                  isPhoneNumber
                    ? `tel:+${phonenumber as string}`
                    : (item.url as string)
                }
                className=" relative flex h-full items-center overflow-hidden py-2.5 after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:-translate-x-full after:bg-pr-red-200 after:transition after:content-[''] hover:after:translate-x-0 focus:after:translate-x-0"
              >
                <span className="text-[15px] xl:text-pr-lg">{item.title}</span>
              </SmartLink>
            </li>
          );
        case "ProductNavigationGroup":
          const label = item.label;
          const linksCollection = item.linksCollection;
          const items = linksCollection?.items as
            | ProductNavigationGroupLinksCollectionItem[]
            | undefined;

          const heading = item.ctaHeading as string;
          const text = item.ctaText?.json as Document | undefined;
          const ctaTitle = item.ctaTitle;
          const ctaUrl = item.ctaUrl;
          const featuredLink = item.featuredLink;
          const featuredLinkLabel = item.featuredLinkLabel;
          const featuredImage = item.featuredImage;
          const featuredText = item.featuredText;

          return (
            <li
              key={index}
              className="py-2"
              onMouseOver={() => handleMouseEnter(index)}
              onMouseOut={() => handleMouseLeave(index)}
            >
              <button
                className={`relative h-full overflow-hidden py-2.5 text-left `}
              >
                {label && (
                  <span className={`text-[15px] font-normal xl:text-pr-lg`}>
                    {label}
                  </span>
                )}
                <div
                  className={`w-full ${isHovered[index] ? "block" : "hidden"}`}
                >
                  <div className="clip-path absolute right-[calc(50%)] top-[calc(76%)] z-40 translate-x-1/2 px-2">
                    <svg
                      fill="white"
                      id="triangle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="17px"
                      viewBox="0 0 100 100"
                    >
                      <polygon points="50 15, 100 100, 0 100" />
                    </svg>
                  </div>
                </div>
              </button>
              <div className={`${isHovered[index] ? "block" : "hidden"}`}>
                <div>
                  <div className="absolute left-0 top-[calc(90%)] z-30 w-full">
                    <ProductContainer>
                      <div className="mx-auto max-w-fit rounded-[20px] bg-white px-10 pb-[1.625rem] pt-10 shadow-navBox">
                        {(heading || text) && (
                          <div className="relative mt-20 overflow-hidden pt-8">
                            <svg
                              viewBox="0 0 180 182"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute left-[2%] w-[13%]"
                            >
                              <path
                                d="M0 0C0 25.0106 20.2872 45.2977 45.2978 45.2977C70.3084 45.2977 90.5955 25.0106 90.5955 0H0Z"
                                className="fill-[#FFE7B8]"
                              />
                              <path
                                d="M0 45.2969C0 70.3074 20.2871 90.5946 45.2977 90.5946C70.3083 90.5946 90.5955 70.3074 90.5955 45.2969H0Z"
                                className="fill-[#FFE7B8]"
                              />
                              <circle
                                cx="44.5671"
                                cy="136.624"
                                r="44.5671"
                                className="fill-[#CFEEF1]"
                              />
                              <path
                                d="M89.1348 181.191L89.1348 90.5959C139.171 90.5959 179.73 131.155 179.73 181.191L89.1348 181.191Z"
                                className="fill-[#C6E7D0]"
                              />
                            </svg>
                            <svg
                              viewBox="0 0 180 180"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute right-[2%] w-[13%]"
                            >
                              <path
                                d="M3.93402e-06 180L87 180C87 130.292 48.0507 90 0 90L3.93402e-06 180Z"
                                className="fill-[#C6E7D0]"
                              />
                              <path
                                d="M179 91H90V180H179V91Z"
                                className="fill-[#FFE7B8]"
                              />
                              <path
                                d="M135 90.194C110.147 90.1122 90 69.8987 90 45.0459L90 0.000350952L180 0.296481L180 45.3421C180 70.1949 159.852 90.2757 135 90.194Z"
                                className="fill-[#F9AE9F] opacity-[.35]"
                              />
                            </svg>
                            <div className="relative mx-auto flex min-h-[190px] max-w-[902px] flex-col justify-center space-y-4 text-center">
                              {heading && (
                                <h2 className="type-pr-gamma">
                                  {returnLineBreaks(heading)}
                                </h2>
                              )}
                              {text && (
                                <div className="type-pr-zeta mx-auto max-w-[784px]">
                                  <RichText text={text} />
                                </div>
                              )}
                              {ctaUrl && ctaTitle && (
                                <div>
                                  <SmartLink
                                    href={ctaUrl}
                                    className="type-pr-zeta link-underline-animated-sm text-pr-red-600"
                                  >
                                    {ctaTitle}
                                  </SmartLink>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {featuredLink && (
                          <a
                            href={featuredLink}
                            className="group mx-auto flex max-w-[675px] gap-10 border-b pb-6"
                          >
                            <figure className="relative h-[112px] w-[200px] shrink-0 self-start">
                              <ContentfulImage
                                image={featuredImage as IContentfulImage}
                                layout="fill"
                                className="object-cover"
                              />
                            </figure>
                            <div className=" space-y-2">
                              <div className="link-underline-animated-sm text-pr-lg">
                                {featuredLinkLabel}
                              </div>
                              <div className="text-[15px] text-gray-400 ">
                                <div className="link-underline-animated-sm">
                                  {featuredText}
                                </div>
                              </div>
                            </div>
                          </a>
                        )}
                        <ul className="mx-auto grid grid-cols-12 gap-x-10">
                          {items && renderNavigationLinksDesktop(items, index)}
                        </ul>
                      </div>
                    </ProductContainer>
                  </div>
                </div>
              </div>
            </li>
          );
        case "ProductPillarNavigation":
          const labelPillar = item.label;
          const linksCollectionPillar = item.pillarOneSubnavigationCollection;
          const itemsPillar = linksCollectionPillar?.items as
            | ProductPillarSubNavigation[]
            | undefined;
          const linksCollectionPillarTwo =
            item.pillarTwoSubnavigationCollection;
          const itemsPillarTwo = linksCollectionPillarTwo?.items as
            | ProductPillarSubNavigation[]
            | undefined;
          const linksCollectionPillarThree =
            item.pillarThreeSubnavigationCollection;
          const itemsPillarThree = linksCollectionPillarThree?.items as
            | ProductPillarSubNavigation[]
            | undefined;
          const linksCollectionPillarFour =
            item.pillarFourSubnavigationCollection;
          const itemsPillarFour = linksCollectionPillarFour?.items as
            | ProductPillarSubNavigation[]
            | undefined;
          return (
            <li
              key={index}
              className="py-2"
              onMouseEnter={handlePillarMouseEnter}
              onMouseLeave={handlePillarMouseLeave}
              onClick={handlePillarMouseLeave}
            >
              <button
                className={`relative h-full overflow-hidden py-2.5 text-left`}
              >
                {labelPillar && (
                  <span className={`text-[15px] xl:text-pr-lg`}>
                    {labelPillar}
                  </span>
                )}
                <div
                  className={`w-full ${isPillarHovered ? "block" : "hidden"}`}
                >
                  <div className="clip-path absolute right-[calc(50%)] top-[calc(76%)] z-40 translate-x-1/2 px-2">
                    <svg
                      fill="white"
                      id="triangle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="17px"
                      viewBox="0 0 100 100"
                    >
                      <polygon points="50 15, 100 100, 0 100" />
                    </svg>
                  </div>
                </div>
              </button>
              <div className={`${isPillarHovered ? "block" : "hidden"}`}>
                <div>
                  <div className="absolute left-0 top-[calc(90%)] z-30 w-full">
                    <ProductContainer>
                      <div className="mx-auto max-w-fit rounded-[20px] bg-white px-10 pb-[1.625rem] pt-10 shadow-navBox">
                        <ul className=" grid grid-cols-12 gap-x-10">
                          <li
                            key={index}
                            className="col-span-3 p-0 text-center"
                          >
                            <div className="flex flex-col">
                              <div className="flex flex-row items-baseline gap-1.5 pb-2.5">
                                {item.pillarOne?.icon && (
                                  <figure className="relative size-5 shrink-0 self-start">
                                    <ContentfulImage
                                      image={
                                        item.pillarOne.icon as IContentfulImage
                                      }
                                      layout="fill"
                                      className="object-contain"
                                      priority={true}
                                    />
                                  </figure>
                                )}
                                <SmartLink
                                  href={contentfulEntryUrl(
                                    item.pillarOne as ContentfulEntryWithUrl
                                  )}
                                  className="group"
                                >
                                  <span className="type-pr-zeta link-underline-animated-sm text-pr-lg">
                                    {item.pillarOne?.title}
                                  </span>
                                </SmartLink>
                              </div>
                              {itemsPillar && itemsPillar.length > 0 && (
                                <span className="flex flex-col">
                                  {renderPillarChildren(itemsPillar)}
                                </span>
                              )}
                            </div>
                          </li>
                          <li
                            key={index}
                            className="col-span-3 p-0 text-center"
                          >
                            <div className="flex flex-col">
                              <div className="flex flex-row items-baseline gap-1.5 pb-2.5">
                                {item.pillarTwo?.icon && (
                                  <figure className="relative size-5 shrink-0 self-start">
                                    <ContentfulImage
                                      image={
                                        item.pillarTwo.icon as IContentfulImage
                                      }
                                      layout="fill"
                                      className="object-contain"
                                      priority={true}
                                    />
                                  </figure>
                                )}
                                <SmartLink
                                  href={contentfulEntryUrl(
                                    item.pillarTwo as ContentfulEntryWithUrl
                                  )}
                                  className="group"
                                >
                                  <span className="type-pr-zeta link-underline-animated-sm text-pr-lg">
                                    {item.pillarTwo?.title}
                                  </span>
                                </SmartLink>
                              </div>
                              {itemsPillarTwo && itemsPillarTwo.length > 0 && (
                                <span className="flex flex-col">
                                  {renderPillarChildren(itemsPillarTwo)}
                                </span>
                              )}
                            </div>
                          </li>
                          <li
                            key={index}
                            className="col-span-3 p-0 text-center"
                          >
                            <div className="flex flex-col">
                              <div className="flex flex-row items-baseline gap-1.5 pb-2.5">
                                {item.pillarThree?.icon && (
                                  <figure className="relative size-5 shrink-0 self-start">
                                    <ContentfulImage
                                      image={
                                        item.pillarThree
                                          .icon as IContentfulImage
                                      }
                                      layout="fill"
                                      className="object-contain"
                                      priority={true}
                                    />
                                  </figure>
                                )}
                                <SmartLink
                                  href={contentfulEntryUrl(
                                    item.pillarThree as ContentfulEntryWithUrl
                                  )}
                                  className="group"
                                >
                                  <span className="type-pr-zeta link-underline-animated-sm text-pr-lg">
                                    {item.pillarThree?.title}
                                  </span>
                                </SmartLink>
                              </div>
                              {itemsPillarThree &&
                                itemsPillarThree.length > 0 && (
                                  <span className="flex flex-col">
                                    {renderPillarChildren(itemsPillarThree)}
                                  </span>
                                )}
                            </div>
                          </li>
                          <li
                            key={index}
                            className="col-span-3 p-0 text-center"
                          >
                            <div className="flex flex-col">
                              <div className="flex flex-row items-baseline gap-1.5 pb-2.5">
                                {item.pillarFour?.icon && (
                                  <figure className="relative size-5 shrink-0 self-start">
                                    <ContentfulImage
                                      image={
                                        item.pillarFour.icon as IContentfulImage
                                      }
                                      layout="fill"
                                      className="object-contain"
                                      priority={true}
                                    />
                                  </figure>
                                )}
                                <SmartLink
                                  href={contentfulEntryUrl(
                                    item.pillarFour as ContentfulEntryWithUrl
                                  )}
                                  className="group"
                                >
                                  <span className="type-pr-zeta link-underline-animated-sm text-pr-lg">
                                    {item.pillarFour?.title}
                                  </span>
                                </SmartLink>
                              </div>
                              {itemsPillarFour &&
                                itemsPillarFour.length > 0 && (
                                  <span className="flex flex-col">
                                    {renderPillarChildren(itemsPillarFour)}
                                  </span>
                                )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </ProductContainer>
                  </div>
                </div>
              </div>
            </li>
          );
      }
    });
  };

  const renderNavigationCtasDesktop = (items: ProductNavigationCta[]) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationCta":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          const phonenumber: string | undefined = isPhoneNumber
            ? item.url?.replace(/[^\d]/g, "")
            : "";
          return (
            <div key={index}>
              {item.title && item.url && item.icon == null && (
                <SmartLink
                  href={
                    isPhoneNumber ? `tel:+${phonenumber as string}` : item.url
                  }
                  className={
                    "text-pr-dark-gray-500 " +
                    (item.style == "Solid"
                      ? "pr-button pr-button-teal pr-button-lg !text-white"
                      : "link-underline-animated-sm text-[15px]")
                  }
                >
                  {item.title}
                </SmartLink>
              )}
              {item.icon && item.url && (
                <SmartLink
                  href={
                    isPhoneNumber ? `tel:+${phonenumber as string}` : item.url
                  }
                  className="pr-button"
                >
                  <figure className="relative size-8 shrink-0">
                    <ContentfulImage
                      image={item.icon as IContentfulImage}
                      layout="fill"
                      className="object-contain"
                      priority={true}
                    />
                  </figure>
                </SmartLink>
              )}
            </div>
          );
      }
    });
  };

  const renderNavigationLinksDesktop = (
    items: ProductNavigationGroupLinksCollectionItem[],
    parentIndex: number
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationLink":
          const isPhoneNumber: boolean =
            item.linkType?.toLowerCase().split(" ").join("") == "phonenumber";
          const phonenumber: string | undefined = isPhoneNumber
            ? item.url?.replace(/[^\d]/g, "")
            : "";
          return (
            <li
              key={index}
              className={`col-span-4 w-[200px] py-3.5 ${
                index >= 3 ? "border-t border-[#D9D9D9]" : " "
              }`}
            >
              <SmartLink
                href={
                  isPhoneNumber
                    ? `tel:+${phonenumber as string}`
                    : (item.url as string)
                }
                className="group"
                onClick={() => handleMouseLeave(parentIndex)}
              >
                <span className="type-pr-zeta link-underline-animated-sm text-[15px] xl:text-pr-lg">
                  {item.title}
                </span>
              </SmartLink>
            </li>
          );
        case "Pillar":
          const services = filterNull(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            item.servicesCollection?.items
          ) as Service[] | undefined;

          return (
            <li key={index} className="col-span-3 w-[200px] p-0 text-center">
              <div className="flex flex-row items-baseline gap-4">
                {item.icon && (
                  <figure className="relative size-5 shrink-0 self-start">
                    <ContentfulImage
                      image={item.icon as IContentfulImage}
                      layout="fill"
                      className="object-contain"
                      priority={true}
                    />
                  </figure>
                )}
                <div className="space-y-5 text-left">
                  <SmartLink
                    href={contentfulEntryUrl(item)}
                    className="group"
                    onClick={() => handleMouseLeave(parentIndex)}
                  >
                    <span className="type-pr-zeta link-underline-animated-sm text-pr-lg">
                      {item.title}
                    </span>
                  </SmartLink>
                  {services && services.length > 0 && (
                    <span className="flex flex-col space-y-3">
                      {renderServices(services)}
                    </span>
                  )}
                </div>
              </div>
            </li>
          );
        case "Solution":
          return (
            <li key={index} className="col-span-4 py-4">
              <SmartLink
                href={contentfulEntryUrl(item)}
                className="group inline-flex items-center gap-4"
                onClick={() => handleMouseLeave(parentIndex)}
              >
                {item.icon && (
                  <figure className="relative size-5 shrink-0 self-start">
                    <ContentfulImage
                      image={item.icon as IContentfulImage}
                      layout="fill"
                      className="object-contain"
                      priority={true}
                    />
                  </figure>
                )}
                <div>
                  <span className="type-pr-zeta link-underline-animated-sm text-[15px] xl:text-pr-lg">
                    {item.title}
                  </span>
                </div>
              </SmartLink>
            </li>
          );
        case "ProductSubNavigationGroup":
          const subitems = item.linksCollection?.items as
            | ProductPillarSubNavigationLinksCollectionItem[]
            | undefined;
          return (
            <li key={index} className="col-span-3 w-[200px] p-0 text-center">
              <div className="flex w-full flex-row items-baseline gap-1.5">
                {item.icon && (
                  <figure className="relative size-5 shrink-0 self-start">
                    <ContentfulImage
                      image={item.icon as IContentfulImage}
                      layout="fill"
                      className="object-contain"
                      priority={true}
                    />
                  </figure>
                )}
                <div className="group pb-3.5">
                  <span className="type-pr-zeta text-pr-lg">{item.title}</span>
                </div>
              </div>
              <div className="w-full text-left">
                {subitems && (
                  <span className="flex w-full flex-col">
                    {renderSubNavigationLinks(subitems, parentIndex)}
                  </span>
                )}
              </div>
            </li>
          );
        default:
          return (
            <li
              key={index}
              className={`col-span-4 w-[200px] py-3.5 ${
                index >= 3 ? "border-t border-[#D9D9D9]" : ""
              }`}
            >
              <SmartLink
                href={contentfulEntryUrl(item)}
                className="group"
                onClick={() => handleMouseLeave(parentIndex)}
              >
                <span className="type-pr-zeta link-underline-animated-sm text-[15px] xl:text-pr-lg">
                  {item.title}
                </span>
              </SmartLink>
            </li>
          );
      }
    });
  };

  const renderServices = (items: Service[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          href={contentfulEntryUrl(item)}
          className="type-pr-zeta text-[15px]"
          key={index}
        >
          <div className="link-underline-animated-sm relative w-fit pt-2">
            {item.previewTitle || item.title}

            {item.new == true && (
              <div className="absolute left-full top-0 text-[9px] font-bold text-pr-red-500">
                NEW
              </div>
            )}
          </div>
        </SmartLink>
      );
    });
  };

  const renderPillarChildren = (
    items: ProductPillarSubNavigation[] | ProductNavigationLink[]
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductPillarSubNavigation":
          const subitems = item.linksCollection?.items as
            | ProductPillarSubNavigationLinksCollectionItem[]
            | undefined;
          return (
            <div
              key={index}
              className="col-span-3 border-t border-[#D9D9D9] p-0 py-3 text-center"
            >
              <div className="flex w-full flex-row items-baseline gap-4">
                <SmartLink
                  href={item.labelUrl || ""}
                  linkTag={true}
                  className="group pb-2"
                  onClick={() => handlePillarMouseLeave()}
                >
                  <span className="link-underline-animated-sm text-[15px]">
                    {item.title}
                  </span>
                </SmartLink>
              </div>
              <div className="w-full text-left">
                {subitems && (
                  <span className="flex w-full flex-col space-y-[4px]">
                    {renderSubPillarLinks(subitems)}
                  </span>
                )}
              </div>
            </div>
          );
        default:
          return (
            <div
              key={index}
              className="border-t border-[#D9D9D9] py-4 text-left"
            >
              <SmartLink
                href={item.url as string}
                className="group"
                onClick={() => handlePillarMouseLeave()}
              >
                <span className="link-underline-animated-sm text-[15px]">
                  {item.title}
                </span>
              </SmartLink>
            </div>
          );
      }
    });
  };

  const renderSubNavigationLinks = (
    items: ProductPillarSubNavigationLinksCollectionItem[],
    parentIndex: number
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        default:
          return (
            <div key={index} className="border-t border-[#D9D9D9] py-3.5">
              <SmartLink
                href={contentfulEntryUrl(item)}
                className="group"
                onClick={() => handleMouseLeave(parentIndex)}
              >
                <span className="type-pr-zeta link-underline-animated-sm text-[15px]">
                  {item.title}
                </span>
              </SmartLink>
            </div>
          );
      }
    });
  };

  const renderSubPillarLinks = (
    items: ProductPillarSubNavigationLinksCollectionItem[]
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "Service":
          return (
            <div key={index} className="ml-3.5">
              <SmartLink
                href={contentfulEntryUrl(item) || ""}
                className="group"
              >
                <div className="flex items-baseline gap-1">
                  {item.navigationText && (
                    <div className="text-2xs font-medium uppercase text-red-400">
                      {item.navigationText}
                    </div>
                  )}
                  <span className="link-underline-animated-sm text-[15px] text-gray-400">
                    {item.title}
                  </span>
                </div>
              </SmartLink>
            </div>
          );
        default:
          return (
            <div key={index} className="ml-3.5">
              <SmartLink href={item.url as string} className="group">
                <span className="link-underline-animated-sm text-[15px] text-gray-400">
                  {item.title}
                </span>
              </SmartLink>
            </div>
          );
      }
    });
  };

  const renderSubNavigationLinksMobile = (
    items: ProductPillarSubNavigationLinksCollectionItem[],
    close: (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined
    ) => void
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        default:
          return (
            <div key={index} className="border-b border-[#D9D9D9] py-3.5">
              <SmartLink
                href={contentfulEntryUrl(item)}
                className="group inline-flex items-center gap-4"
                onClick={() => {
                  close();
                }}
              >
                <div>
                  <span className="pl-5 text-[15px]">{item.title}</span>
                </div>
              </SmartLink>
            </div>
          );
      }
    });
  };

  const renderSubNavigationPillarLinksMobile = (
    items: ProductPillarSubNavigationLinksCollectionItem[],
    close: (
      focusableElement?:
        | HTMLElement
        | MutableRefObject<HTMLElement | null>
        | undefined
    ) => void
  ) => {
    return items.map((item, index) => {
      switch (item.__typename) {
        case "ProductNavigationLink":
          return (
            <div key={index} className="py-[3px] pl-8">
              <SmartLink
                href={item.url as string}
                className="group inline-flex items-center gap-4"
                onClick={() => {
                  close();
                }}
              >
                <div>
                  <span className="text-[15px] text-[#696970]">
                    {item.title}
                  </span>
                </div>
              </SmartLink>
            </div>
          );
        default:
          return (
            <div key={index} className="py-[3px] pl-8">
              <SmartLink
                href={contentfulEntryUrl(item)}
                className="group inline-flex items-center gap-4"
                onClick={() => {
                  close();
                }}
              >
                <div>
                  <span className="text-[15px] text-[#696970]">
                    {item.title}
                  </span>
                </div>
              </SmartLink>
            </div>
          );
      }
    });
  };

  return (
    <>
      {/* alert */}
      <div className="bg-pr-yellow-200">{renderAlert(alertType)}</div>

      <Headroom
        disableInlineStyles={true}
        disable={mobileMenuActive}
        className={`sticky top-0 z-20 w-full ${
          mobileMenuActive ? "!fixed" : ""
        }`}
        onPin={() => {
          setHeadroomPinned(true);
        }}
        onUnpin={() => {
          setHeadroomPinned(false);
        }}
      >
        <header
          className={`top-0 z-10 flex h-20 items-center justify-center lg:h-auto lg:items-stretch ${
            navigationStyle == "white" ? "bg-pr-yellow-200" : "bg-pr-yellow-200"
          } ${
            mobileMenuActive
              ? "border-b border-[#D9D9D9] !bg-pr-yellow-100"
              : " border-none"
          }`}
        >
          <ProductContainer>
            {/* mobile */}
            <div className="lg:hidden">
              <Popover className="flex items-center">
                {({ open }) => {
                  // @TODO: would be nice to not have to do this:
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useEffect(() => {
                    setMobileMenuActive(open);
                  }, [open]);

                  return (
                    <>
                      <SmartLink href={"/"} ariaLabel="Home">
                        <Logo className="h-8 w-36" />
                      </SmartLink>

                      <Popover.Button className="ml-auto flex size-9 items-center justify-center">
                        <Menu
                          className={`h-5 w-[30px] stroke-current ${
                            open ? "hidden" : ""
                          }`}
                        />
                        <Close
                          className={`size-6 stroke-current ${
                            open ? "" : "hidden"
                          }`}
                        />
                      </Popover.Button>

                      <Popover.Panel
                        static={true}
                        className={`fixed inset-0 -z-[1] h-screen w-full overflow-auto bg-pr-yellow-100 px-5 py-20 ${
                          alertActive ? "" : "pt-20"
                        } ${open ? "block" : "hidden"}`}
                      >
                        {({ close }) => (
                          <>
                            <ul className="-mx-6">
                              {renderNavigationElementsMobile(
                                combinedNavigationElements,
                                close
                              )}
                            </ul>

                            <div className="mt-[55px] flex w-full flex-col gap-4 sm:flex-row sm:flex-wrap sm:items-center">
                              {navigationCtas &&
                                renderNavigationCtasMobile(
                                  navigationCtas,
                                  close
                                )}
                            </div>
                          </>
                        )}
                      </Popover.Panel>
                    </>
                  );
                }}
              </Popover>
            </div>
            {/* desktop */}
            <div className="hidden items-center gap-6 lg:flex lg:min-h-[92px]">
              <SmartLink href={"/"} ariaLabel="Home">
                <Logo className="h-8 w-36 xl:h-10 xl:w-[186px] " />
              </SmartLink>

              <ul className="mx-auto flex gap-4 self-stretch xl:gap-6 2xl:gap-8 3xl:gap-8">
                {renderNavigationElementsDesktop(combinedNavigationElements)}
              </ul>

              <div className="flex items-center gap-6 py-2">
                {navigationCtas && renderNavigationCtasDesktop(navigationCtas)}
              </div>
            </div>
          </ProductContainer>
        </header>
      </Headroom>
    </>
  );
}

export default ProductNavigation;
