/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import {
  ProductContainer,
  RichText,
  ContentfulImage,
  SmartLink
} from "@src/components";
import Arrow from "@/img/icons/arrow.svg";

export type ProductHeadingWithTextBlockProps = {
  eyebrow?: string | null;
  heading?: string | null;
  text?: Document;
  icon?: IContentfulImage | null;
  backgroundStyle?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductHeadingWithTextBlock({
  eyebrow,
  heading,
  text,
  icon,
  backgroundStyle = "white",
  ctaTitle,
  ctaUrl
}: ProductHeadingWithTextBlockProps) {
  // const dimensions = {
  //   width: 180,
  //   height: 180,
  // };

  // const transforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: dimensions.width,
  //   height: dimensions.height,
  // };

  const defaultBackgroundStyle: string = "white";

  const classNames: { [key: string]: string } = {
    white: "",
    red: `bg-pr-red-500 relative overflow-hidden pb-12 lg:pb-60 -mt-10 md:-mt-16 lg:-mt-24 ${
      icon ? "pt-5 md:pt-20 md:pb-40" : "pt-12 md:py-40"
    }`,
    green: `bg-pr-green-500 relative overflow-hidden pb-12 lg:pb-60 md:-mt-16 lg:-mt-24 ${
      icon ? "pt-5 md:pt-20 md:pb-40" : "pt-12 md:py-40"
    }`,
    yellow: `bg-pr-yellow-500 relative overflow-hidden pb-12 lg:pb-60 md:-mt-16 lg:-mt-24 ${
      icon ? "pt-5 md:pt-20 md:pb-40" : "pt-12 md:py-40"
    }`,
    teal: `bg-pr-teal-600 relative overflow-hidden pb-12 lg:pb-60 md:-mt-16 lg:-mt-24 ${
      icon ? "pt-5 md:pt-20 md:pb-40" : "pt-12 md:py-40"
    }`,
    smallpadding: "bg-white my-8 md:my8"
  };

  const textColClassNames: { [key: string]: string } = {
    white: "",
    red: "lg:space-y-8",
    green: "lg:space-y-8",
    yellow: "lg:space-y-8",
    teal: "lg:space-y-8"
  };

  const headingClassNames: { [key: string]: string } = {
    white: "",
    red: "px-[25%] text-white md:px-0",
    green: "px-[25%] text-white md:px-0",
    yellow: "px-[25%] md:px-0",
    teal: "px-[25%] text-white md:px-0"
  };

  const textClassNames: { [key: string]: string } = {
    white: "text-pr-dark-gray-400",
    red: "xs:px-[20%] text-white lg:!px-0",
    green: "xs:px-[20%] text-white lg:!px-0",
    yellow: "xs:px-[20%] lg:!px-0",
    teal: "xs:px-[20%] text-white lg:!px-0"
  };

  const className =
    classNames[backgroundStyle ? backgroundStyle : defaultBackgroundStyle];

  const textColClassName =
    textColClassNames[
      backgroundStyle ? backgroundStyle : defaultBackgroundStyle
    ];
  const textClassName =
    textClassNames[backgroundStyle ? backgroundStyle : defaultBackgroundStyle];
  const headingClassName =
    headingClassNames[
      backgroundStyle ? backgroundStyle : defaultBackgroundStyle
    ];

  return (
    <div className={`${className}`}>
      <ProductContainer fullWidth={backgroundStyle != "white"}>
        <div className="relative">
          {backgroundStyle != "white" && backgroundStyle != "smallpadding" && (
            <>
              <svg
                viewBox="0 0 1649 563"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute -left-5 top-0 lg:-left-8 lg:-top-20 2xl:-left-24"
              >
                <path
                  d="M140.675 139.832H282.633V277.832H140.675V139.832Z"
                  className="fill-white"
                />
                <path
                  d="M0.000745453 277.832L140.93 277.832L140.93 139.832C63.0939 139.832 0.000751739 201.614 0.000745453 277.832Z"
                  className="fill-white opacity-60"
                />
                <path
                  d="M70.465 279.84C108.802 279.84 139.898 310.07 139.898 347.338C139.898 384.606 108.802 414.836 70.465 414.836C32.1281 414.836 1.03125 384.606 1.03125 347.338C1.03125 310.07 32.1281 279.84 70.465 279.84Z"
                  className="fill-white"
                />
                <path
                  d="M141.188 277.832C219.594 277.832 283.148 339.615 283.148 415.835L141.188 415.835L141.188 277.832Z"
                  className="fill-pr-dark-gray-500"
                />
                <path
                  d="M282.629 0.999988C358.224 0.999995 419.5 64.0335 419.5 141.796L282.629 141.796L282.629 0.999988Z"
                  className="fill-white opacity-[.35]"
                />
                <path
                  d="M257.336 559.398C257.336 483.804 320.369 422.527 398.132 422.527L398.132 559.398L257.336 559.398Z"
                  className="hidden fill-white opacity-[.35] lg:block"
                />
              </svg>
              <svg
                viewBox="0 0 1649 563"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute -right-5 top-0 lg:-right-8 lg:-top-20 2xl:-right-24"
              >
                <path
                  d="M1510.48 425.18L1510.48 283.223L1648.48 283.223L1648.48 425.18L1510.48 425.18Z"
                  className="fill-white"
                />
                <path
                  d="M1369.47 421.735L1369.47 562.664L1507.47 562.664C1507.47 484.828 1445.69 421.735 1369.47 421.735Z"
                  className="hidden fill-white opacity-60 lg:block"
                />
                <path
                  d="M1510.48 142.294L1510.48 283.223L1648.48 283.223C1648.48 205.387 1586.69 142.294 1510.48 142.294Z"
                  className="fill-white opacity-60"
                />
                <path
                  d="M1510.47 283.199H1369.54L1369.54 421.199C1447.37 421.199 1510.47 359.417 1510.47 283.199Z"
                  className="fill-white opacity-60"
                />
                <path
                  d="M1230.67 138.637L1371.6 138.637L1371.6 0.636719C1293.77 0.636726 1230.67 62.4186 1230.67 138.637Z"
                  className="fill-white opacity-60"
                />
                <path
                  d="M1298.11 286.703C1336.44 286.703 1367.54 316.933 1367.54 354.201C1367.54 391.469 1336.44 421.699 1298.11 421.699C1259.77 421.699 1228.67 391.469 1228.67 354.201C1228.67 316.933 1259.77 286.703 1298.11 286.703Z"
                  className="fill-white"
                />
                <path
                  d="M1370.48 211.726C1370.48 173.39 1400.71 142.293 1437.97 142.293C1475.24 142.293 1505.47 173.39 1505.47 211.726C1505.47 250.063 1475.24 281.16 1437.97 281.16C1400.71 281.16 1370.48 250.063 1370.48 211.726Z"
                  className="fill-pr-dark-gray-500"
                />
                <path
                  d="M1508.47 141.035C1432.88 141.035 1371.6 78.0016 1371.6 0.238949L1508.47 0.238966L1508.47 141.035Z"
                  className="fill-white opacity-[.35]"
                />
              </svg>
            </>
          )}

          <div className="relative mx-auto max-w-[770px] text-center">
            {icon && (
              <div className="mx-auto flex size-8 items-center justify-center rounded-full bg-white md:size-[106px] lg:size-[138px]">
                <figure className="relative size-4 md:size-11 lg:size-[60px]">
                  <ContentfulImage
                    image={icon}
                    layout="fill"
                    className="object-contain"
                  />
                </figure>
              </div>
            )}
            <div className={`space-y-1 ${icon ? "mt-2 lg:mt-16" : ""}`}>
              {eyebrow && (
                <span className="type-pr-theta md:type-pr-eta text-pr-dark-gray-400">
                  {eyebrow}
                </span>
              )}
              <div className={`space-y-4 ${textColClassName}`}>
                {heading && (
                  <h2
                    className={`type-pr-delta md:type-pr-beta font-bold ${headingClassName}`}
                  >
                    {returnLineBreaks(heading)}
                  </h2>
                )}
                {text && (
                  <div className={`type-pr-eta ${textClassName}`}>
                    <RichText text={text} />
                  </div>
                )}
                {ctaUrl && ctaTitle && (
                  <SmartLink
                    href={ctaUrl}
                    className="link-underline-animated-sm inline-flex items-center gap-3 text-pr-red-600"
                  >
                    <span className="text-pr-xl">{ctaTitle}</span>
                    <svg
                      width="39"
                      height="16"
                      viewBox="0 0 39 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.7071 8.70711C39.0976 8.31658 39.0976 7.68342 38.7071 7.29289L32.3431 0.928932C31.9526 0.538408 31.3195 0.538408 30.9289 0.928932C30.5384 1.31946 30.5384 1.95262 30.9289 2.34315L36.5858 8L30.9289 13.6569C30.5384 14.0474 30.5384 14.6805 30.9289 15.0711C31.3195 15.4616 31.9526 15.4616 32.3431 15.0711L38.7071 8.70711ZM0 9H38V7H0V9Z"
                        fill="#F05843"
                      />
                    </svg>
                  </SmartLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductHeadingWithTextBlock;
