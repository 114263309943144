import * as React from "react";
import { RichText, SmartLink } from "@src/components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";
import { Document } from "@contentful/rich-text-types";
import { Disclosure, Transition } from "@headlessui/react";
import {
  PricingSlides,
  PricingSlidesCard
} from "@src/lib/services/server/contentful";

export type PricingTabbedContentBlockProps = {
  slides?: PricingSlides[] | null | undefined;
  bannerHeading?: string | null;
  bannerSubheading?: string | null;
  table?: Document | null;
  ctaText?: string | null;
  ctaUrl?: string | null;
};

const renderCard = (card: PricingSlidesCard | null | undefined) => {
  const priceDetails = card?.priceDetails?.json as Document | undefined;
  const description = card?.description?.json as Document | undefined;
  const details = card?.details?.json as Document | undefined;
  return (
    <div
      className={`relative flex !h-auto w-full bg-white lg:min-w-[360px] lg:max-w-[360px]`}
    >
      {card?.percentOff && (
        <div className="corner font-bold text-white">
          <span>
            <div className="flex flex-col items-end">
              <div className="font-sans text-[22px] font-bold">
                {card.percentOff}
              </div>{" "}
              <div className="ml-1 font-sans text-[14px] font-bold">OFF</div>
            </div>
          </span>
        </div>
      )}
      <div className="flex flex-col pt-[30px] lg:pt-10">
        <h3 className="type-pr-delta px-7 text-[30px] lg:text-pr-5xl">
          {card?.heading}
        </h3>

        <div className="px-7 pb-6 pt-4 lg:pt-6">
          <div className="flex flex-row items-center gap-3">
            {card?.previousPrice && (
              <div className="type-pr-zeta text-[30px] text-gray-300">
                <span className="strikethrough-card">
                  ${card.previousPrice}
                </span>
              </div>
            )}
            <div className="flex items-baseline">
              {priceDetails && (
                <div className="card-details card-details-price font-bold">
                  <RichText text={priceDetails} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="px-7">
          <SmartLink
            href={card?.primaryCtaUrl || ""}
            className={"pr-button pr-button-lg pr-button-teal"}
          >
            {card?.primaryCtaTitle}
          </SmartLink>
        </div>

        {description && (
          <div className="px-7 pt-[30px] text-[15px] leading-pr-loose text-pr-dark-gray-500 lg:pt-10">
            <RichText text={description} />
          </div>
        )}

        <div className="px-7 pt-4 lg:hidden">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between gap-4 py-3">
                  <div
                    className={`text-xl font-bold ${open ? "hidden" : "block"}`}
                  >
                    See more
                  </div>
                  <div
                    className={`text-xl font-bold ${open ? "block" : "hidden"}`}
                  >
                    See less
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="12"
                      viewBox="0 0 20 12"
                      fill="none"
                      className={`${open ? "rotate-180" : ""}`}
                    >
                      <path
                        d="M1 11L10.0657 2L19 11"
                        stroke="#30323B"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </Disclosure.Button>
                <Transition
                  className="overflow-hidden"
                  enter="transition transition-[max-height] duration-200 ease-in"
                  enterFrom="transform max-h-0"
                  enterTo="transform max-h-screen"
                  leave="transition transition-[max-height] duration-200 ease-out"
                  leaveFrom="transform max-h-screen"
                  leaveTo="transform max-h-0"
                >
                  <Disclosure.Panel static={true} className="pb-6 md:pb-9">
                    <div className="!mt-0 pt-3">
                      <div className="card-details-details text-pr-gray-500 text-[15px]">
                        {details && (
                          <div className="[&_ul]:pl-2.5">
                            <RichText text={details} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col justify-self-end pt-5">
                      <SmartLink
                        href={card?.secondaryCtaUrl || ""}
                        className={`pt-[18px] text-pr-base text-pr-teal-600`}
                      >
                        <div className="link-underline-animated-sm">
                          {card?.secondaryCtaTitle}
                        </div>
                      </SmartLink>
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>

        {/* Desktop */}
        <div className="hidden lg:block">
          <div className="!mt-0 pt-4 lg:pt-5">
            <div className="card-details-details text-pr-gray-500 h-[320px] px-7 text-[15px]">
              {details && (
                <div className="[&_ul]:pl-2.5">
                  <RichText text={details} />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-self-end px-7 pt-10">
            <SmartLink
              href={card?.secondaryCtaUrl || ""}
              className={`pt-[18px] text-pr-base text-pr-teal-600`}
            >
              <div className="link-underline-animated-sm">
                {card?.secondaryCtaTitle}
              </div>
            </SmartLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export function PricingTabbedContent({
  bannerHeading,
  bannerSubheading,
  table,
  ctaText,
  ctaUrl,
  slides
}: PricingTabbedContentBlockProps) {
  const paginationClassName = "pagination-pricing";
  const ctaClassName = "swiper-pagination-pricing";
  const ctaActiveClassName = "bg-white";

  return (
    <div className="border-t border-pr-teal-600 bg-white">
      <div
        className={`flex flex-row flex-wrap items-center justify-between md:flex-row ${paginationClassName}`}
      ></div>
      <div className="mx-auto w-full max-w-screen-2lg">
        {slides && (
          <Swiper
            pagination={{
              el: `.${paginationClassName}`,
              clickable: true,
              bulletActiveClass: ctaActiveClassName,
              bulletClass: ctaClassName,
              renderBullet: function (index, className) {
                return `<span class="${className} ${
                  index == 0 ? " xl:pl-[285px]" : " xl:pr-[285px] "
                } flex gap-4 items-center"> ${`<img src= ${
                  slides[index].icon?.url as string
                } />`} ${slides[index].heading as string} </span>`;
              }
            }}
            modules={[Pagination]}
            autoHeight={true}
            initialSlide={2}
            className="mySwiper"
          >
            {slides.map((item, index) => (
              <SwiperSlide key={index} className="swiper-no-swiping">
                <div className="mx-auto flex flex-col divide-y divide-[#B0B0B0] border-b border-[#B0B0B0] bg-white lg:flex-row lg:justify-center lg:divide-x lg:divide-y-0 lg:pb-[60px] lg:pt-[85px]">
                  {item.cardsCollection?.items.map((card, index) => (
                    <div key={index}>{renderCard(card)}</div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <div className="items-center bg-white px-7 py-10 lg:grid lg:grid-cols-3 lg:px-0 lg:pb-[85px] lg:pt-[60px]">
          <div className="space-y-4 lg:space-y-1">
            {bannerHeading && (
              <div className="text-[30px] font-medium">{bannerHeading}</div>
            )}
            {bannerSubheading && (
              <div className="pb-5 text-pr-base text-pr-dark-gray-400 lg:pb-0">
                {bannerSubheading}
              </div>
            )}
          </div>
          {table && (
            <div className="pricing-table [&_table]:mx-auto [&_table]:w-full [&_table]:lg:w-[250px]">
              <RichText text={table} />
            </div>
          )}
          <div className="mx-auto pt-[30px] text-center lg:pt-0">
            <SmartLink
              href={ctaUrl || ""}
              className={" pr-button pr-button-lg pr-button-teal"}
            >
              {ctaText}
            </SmartLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingTabbedContent;
