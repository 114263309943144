import * as React from "react";
import {
  ProductContainer,
  RichText,
  SmartLink,
  ContentfulImage
} from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";

export type ProductCtaProps = {
  heading?: string | null;
  text?: Document | null;
  image?: IContentfulImage | null;
  imageOrientation?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaId?: string | null;
  backgroundStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductCta({
  heading,
  text,
  image,
  imageOrientation,
  ctaTitle,
  ctaUrl,
  ctaId,
  backgroundStyle
}: ProductCtaProps) {
  const defaultBackgroundStyle: string = "red";

  const backgroundClassNames: { [key: string]: string } = {
    yellow: "bg-pr-yellow-200 text-pr-dark-gray-500",
    red: "bg-pr-red-500 text-white"
  };

  const buttonClassNames: { [key: string]: string } = {
    yellow: "pr-button-red",
    red: "pr-button-black"
  };

  const backgroundClassName =
    backgroundClassNames[
      backgroundStyle ? backgroundStyle : defaultBackgroundStyle
    ];

  const buttonClassName =
    buttonClassNames[
      backgroundStyle ? backgroundStyle : defaultBackgroundStyle
    ];

  const dimensions = {
    width: 1408,
    height: 858
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  if (imageOrientation === "right" && image) {
    return (
      <ProductContainer>
        <div
          className={`items-center overflow-hidden rounded-3xl  px-[23px] py-[30px] lg:flex lg:flex-row lg:gap-[45px] lg:py-[50px] lg:pl-[56px] lg:pr-[80px] ${backgroundClassName}`}
        >
          <div className="hidden lg:block">
            <figure className="relative size-full">
              <img src={image.url || ""} alt={image.description || ""} />
            </figure>
          </div>

          <div className="flex w-full flex-col justify-center gap-5 lg:flex-row lg:items-center lg:justify-between lg:gap-0">
            <div className="mx-auto flex max-w-[650px] flex-col gap-6 text-center lg:mx-0 lg:gap-2 lg:text-left">
              {heading && (
                <h2 className="type-pr-beta">{returnLineBreaks(heading)}</h2>
              )}

              <div className="mx-auto lg:hidden">
                <figure className="relative size-full">
                  <img src={image.url || ""} alt={image.description || ""} />
                </figure>
              </div>

              {text && (
                <div className={`lg:type-pr-zeta mt-2 text-pr-xl`}>
                  <RichText text={text} />
                </div>
              )}
            </div>
            {ctaUrl && ctaTitle && (
              <div className="mx-auto lg:mx-0">
                <SmartLink
                  href={ctaUrl}
                  className={`pr-button pr-button-lg ${buttonClassName}`}
                  ctaId={ctaId}
                >
                  {ctaTitle}
                </SmartLink>
              </div>
            )}
          </div>
        </div>
      </ProductContainer>
    );
  } else {
    return (
      <ProductContainer>
        <div
          className={`overflow-hidden rounded-3xl ${backgroundClassName} ${
            image
              ? "md:grid md:grid-cols-4"
              : "lg:grid lg:grid-cols-4 lg:gap-10 xl:gap-20"
          }`}
        >
          <div
            className={`px-5 lg:px-12 ${
              image
                ? "py-5 md:col-span-2 md:py-[74px]"
                : "pt-5 md:pt-12 lg:col-span-3 lg:py-12 lg:pr-0"
            }`}
          >
            {heading && (
              <h2 className={`${image ? "type-pr-beta" : "type-pr-gamma"}`}>
                {returnLineBreaks(heading)}
              </h2>
            )}
            {text && (
              <div
                className={`type-pr-zeta mt-2 ${
                  image ? "max-w-[35ch] md:mt-3" : ""
                }`}
              >
                <RichText text={text} />
              </div>
            )}

            {image && ctaUrl && ctaTitle && (
              <div className="mt-7 md:mt-10">
                <SmartLink
                  href={ctaUrl}
                  className={`pr-button pr-button-lg ${buttonClassName}`}
                  ctaId={ctaId}
                >
                  {ctaTitle}
                </SmartLink>
              </div>
            )}
          </div>

          {!image && ctaUrl && ctaTitle && (
            <div className="mt-7 px-5 pb-5 md:pb-12 lg:col-span-1 lg:mt-0 lg:flex lg:items-center lg:justify-center lg:p-12 lg:pl-0">
              <SmartLink
                href={ctaUrl}
                className={"pr-button pr-button-lg pr-button-black"}
                ctaId={ctaId}
              >
                {ctaTitle}
              </SmartLink>
            </div>
          )}

          {image && (
            <div className="md:col-span-2">
              <figure className="relative aspect-[1408/858] size-full">
                <ContentfulImage
                  image={image}
                  transforms={transforms}
                  layout="fill"
                  className="object-cover"
                />
              </figure>
            </div>
          )}
        </div>
      </ProductContainer>
    );
  }
}

export default ProductCta;
