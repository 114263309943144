/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import * as React from "react";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { ProductContainer, ContentfulImage, SmartLink } from "@src/components";
import { IContentfulImage } from "@src/lib/types";

export type ProductHeroServiceProps = {
  heading?: string | null;
  text?: string | null;
  image?: IContentfulImage | null;
  eyebrow?: string | null;
  icon?: IContentfulImage | null;
  heroStyle?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
  priority?: boolean;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductHeroService({
  heading,
  text,
  image,
  eyebrow,
  icon,
  heroStyle,
  ctaTitle,
  ctaUrl,
  ctaStyle,
  priority = true
}: ProductHeroServiceProps) {
  const imageDimensions = {
    width: image?.width || 2428,
    height: image?.height || 1554
  };

  const imageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: imageDimensions.width,
    height: imageDimensions.height
  };

  // const iconDimensions = {
  //   width: 180,
  //   height: 180,
  // };

  // const iconTransforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: iconDimensions.width,
  //   height: iconDimensions.height,
  // };

  const defaultCtaStyle: string = "red";

  const ctaClassNames: { [key: string]: string } = {
    red: "pr-button-red",
    green: "bg-pr-green-500 text-white",
    yellow: "bg-pr-yellow-500 text-white",
    teal: "bg-pr-teal-500 text-white",
    black: "pr-button-black"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <div className="overflow-hidden py-8">
      {heroStyle == "imageonright" ||
      heroStyle == "imageonrightwithmotif" ||
      heroStyle == "motif" ? (
        <ProductContainer fullWidth={true}>
          <div className="grid gap-6 md:grid-cols-2 md:gap-0">
            <div className="flex w-full flex-col justify-center space-y-8 md:max-w-[704px] md:justify-self-end">
              <div className="space-y-6 lg:space-y-11">
                {eyebrow && icon && (
                  <div className="flex items-center gap-2.5 lg:gap-4">
                    <figure className="relative size-[22px] shrink-0 lg:size-[30px]">
                      <ContentfulImage
                        image={icon}
                        layout="fill"
                        className="object-contain"
                        priority={priority}
                      />
                    </figure>
                    <p className="lg:type-pr-zeta text-[18px] text-pr-dark-gray-400">
                      {eyebrow}
                    </p>
                  </div>
                )}
                <div className="space-y-3 lg:space-y-5">
                  {heading && (
                    <h1 className="type-pr-alpha">
                      {returnLineBreaks(heading)}
                    </h1>
                  )}
                  {text && (
                    <div className="type-pr-zeta max-w-[40ch] text-pr-dark-gray-400 lg:text-pr-4xl">
                      <p>{returnLineBreaks(text)}</p>
                    </div>
                  )}
                </div>
              </div>
              {ctaUrl && ctaTitle && (
                <div>
                  <SmartLink
                    href={ctaUrl}
                    className={`pr-button pr-button-lg ${ctaClassName}`}
                  >
                    {ctaTitle}
                  </SmartLink>
                </div>
              )}
            </div>
            <div className="-mr-5 flex flex-col justify-center overflow-hidden md:pl-5 lg:-mr-8 lg:pl-10 xl:pl-20 2xl:-mr-24">
              <div className="relative mr-[-20%] pl-[10%] md:mr-[-40%] md:pl-[8%]">
                {heroStyle == "imageonrightwithmotif" && (
                  <svg
                    viewBox="0 0 96 97"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute bottom-5 left-0 w-[22%] md:bottom-10"
                  >
                    <path
                      d="M71.756 48.36h24.07V.22h-24.07c-13.29 0-24.07 10.777-24.07 24.062 0 13.303 10.78 24.079 24.07 24.079Z"
                      className="fill-pr-teal-600 opacity-40"
                    />
                    <path
                      d="M95.826 72.43V48.36h-48.14v24.07c0 13.29 10.776 24.07 24.06 24.07 13.304 0 24.08-10.78 24.08-24.07Z"
                      className="fill-pr-yellow-400 opacity-40"
                    />
                    <path
                      d="M0 .22h48.14v48.14H0V.22Z"
                      className="fill-pr-teal-100 opacity-40"
                    />
                  </svg>
                )}
                {heroStyle == "motif" && (
                  <svg
                    viewBox="0 0 211 378"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute bottom-5 left-0 w-[22%] md:bottom-10"
                  >
                    <path
                      d="M0 0h105v272H0V0Z"
                      className="fill-pr-red-100 opacity-40"
                    />
                    <path
                      d="M211 219v-53H105v53c0 29.263 23.728 53 52.98 53 29.292 0 53.02-23.737 53.02-53Z"
                      className="fill-pr-red-400 opacity-40"
                    />
                    <path
                      d="M105 272h106v106H105V272Z"
                      className="fill-pr-teal-100 opacity-40"
                    />
                  </svg>
                )}
                <figure>
                  <ContentfulImage
                    image={image}
                    transforms={imageTransforms}
                    className="relative"
                    priority={priority}
                  />
                </figure>
              </div>
            </div>
          </div>
        </ProductContainer>
      ) : (
        <ProductContainer fullWidth={true}>
          <div className="relative">
            {heroStyle == "imageabovewithmotif" && (
              <svg
                viewBox="0 0 1650 567"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 -inset-x-7 h-full w-[calc(100%+3.5rem)] md:-inset-x-8 md:w-[calc(100%+4rem)] 2xl:-inset-x-24 2xl:w-[calc(100%+12rem)] 4xl:-inset-x-[6.5rem] 4xl:w-[calc(100%+13rem)]"
              >
                <path
                  d="M242.546 142.627L242.546 283.493C168.954 283.493 109.302 220.428 109.302 142.627L242.546 142.627V142.627Z"
                  className="fill-pr-dark-gray-700 opacity-[.35]"
                />
                <path
                  d="M1398.55 426.122L1398.55 285.256C1472.14 285.256 1531.79 348.321 1531.79 426.122L1398.55 426.122V426.122Z"
                  className="fill-pr-green-500 opacity-[.35]"
                />
                <path
                  d="M242.664 426.134L242.664 567C168.459 567 108.309 503.935 108.309 426.134L242.664 426.134V426.134Z"
                  className="fill-pr-red-400 opacity-[.35]"
                />
                <path
                  d="M242.68 426.124L108.325 426.124C108.325 347.674 168.475 284.084 242.68 284.084L242.68 426.124Z"
                  className="fill-pr-teal-400 opacity-[.35]"
                />
                <path
                  d="M1398.41 142.625L1532.77 142.625C1532.77 221.075 1472.62 284.665 1398.41 284.665L1398.41 142.625Z"
                  className="fill-pr-teal-400 opacity-[.35]"
                />
                <path
                  d="M109.309 142.617L109.309 0.577142C182.901 0.577146 242.554 64.1675 242.554 142.617L109.309 142.617Z"
                  className="fill-pr-green-500 opacity-[.35]"
                />
                <path
                  d="M-9.38207e-05 283.507L-9.98619e-05 425.547L133.244 283.507L-9.38207e-05 283.507Z"
                  className="fill-pr-green-500 opacity-[.35]"
                />
                <path
                  d="M1651.21 -0.000160586L1513 -0.000173004L1651.21 136.938L1651.21 -0.000160586Z"
                  className="fill-pr-dark-gray-700 opacity-[.35]"
                />
              </svg>
            )}

            <div className="relative pl-6 pr-[26px] md:pl-[10%] md:pr-[10.5%]">
              <figure className="relative mx-auto w-full max-w-[1168px]">
                <ContentfulImage
                  image={image}
                  transforms={imageTransforms}
                  priority={priority}
                />
              </figure>
            </div>
          </div>
          <div className="mx-auto mt-4 w-full max-w-[1168px]">
            <div className="space-y-6 lg:space-y-11">
              {eyebrow && icon && (
                <div className="flex items-center gap-2.5 lg:col-span-full lg:gap-4">
                  <figure className="relative size-[22px] shrink-0 lg:size-[30px]">
                    <ContentfulImage
                      image={icon}
                      layout="fill"
                      className="object-contain"
                      priority={priority}
                    />
                  </figure>
                  <p className="lg:type-pr-zeta text-[18px] text-pr-dark-gray-400">
                    {eyebrow}
                  </p>
                </div>
              )}
              <div className="space-y-3 lg:space-y-5">
                {heading && (
                  <h1 className="type-pr-alpha">{returnLineBreaks(heading)}</h1>
                )}
                {text && (
                  <div className="type-pr-zeta max-w-[40ch] text-pr-dark-gray-400 lg:text-pr-4xl">
                    <p>{returnLineBreaks(text)}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ProductContainer>
      )}
    </div>
  );
}

export default ProductHeroService;
