import * as React from "react";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, ContentfulImage, RichText } from "@src/components";

export type ProductFeaturedImageWithTextBlockProps = {
  eyebrow?: string | null;
  heading?: string | null;
  text?: Document;
  image?: IContentfulImage | null;
  backgroundStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductFeaturedImageWithTextBlock({
  eyebrow,
  heading,
  text,
  image,
  backgroundStyle = "white"
}: ProductFeaturedImageWithTextBlockProps) {
  const dimensions = {
    width: image?.width || 2428,
    height: image?.height || 1554
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  return (
    <div
      className={` ${
        backgroundStyle == "yellow" ? "bg-pr-yellow-200 py-12 md:py-40" : ""
      }`}
    >
      <ProductContainer fullWidth={true}>
        <div
          className={`space-y-9 lg:space-y-20 ${
            backgroundStyle == "yellow" ? "bg-pr-yellow-200" : ""
          }`}
        >
          {image && (
            <figure className="mx-auto max-w-[1168px]">
              <ContentfulImage image={image} transforms={transforms} />
            </figure>
          )}
          <div className="mx-auto max-w-[902px] space-y-2 text-center lg:space-y-4">
            {eyebrow && (
              <span className="type-pr-theta md:type-pr-eta text-pr-dark-gray-400">
                {eyebrow}
              </span>
            )}
            <div className="space-y-3.5 lg:space-y-6">
              {heading && (
                <h2
                  className={`type-pr-beta ${image ? "xl:text-pr-12xl" : ""}`}
                >
                  {returnLineBreaks(heading)}
                </h2>
              )}
              {text && text.content.length > 0 && (
                <div
                  className={`type-pr-zeta mx-auto max-w-[864px] ${
                    backgroundStyle == "yellow" ? "" : "text-pr-dark-gray-400"
                  }`}
                >
                  <RichText text={text} />
                </div>
              )}
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductFeaturedImageWithTextBlock;
