/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { Popover, Transition } from "@headlessui/react";
import {
  ContentfulImageUrlTransforms,
  contentfulEntryUrl,
  filterNull
} from "@src/lib/utils";
import {
  PopularTopicsCollectionItem,
  AllTopicsCollectionItem,
  FeaturedBlogPostsCollectionItem
} from "@src/lib/services/server/contentful";
import type { BlogNavigationQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";
import {
  SeoEntry,
  SmartLink,
  SubnavigationItem,
  BlogContainer,
  Button,
  ContentfulImage
} from "@src/components";

export type SubnavigationProps = {
  entry?: SeoEntry;
  navigation: BlogNavigationQuery;
};

// @TODO: reimplement popover with popover.button

export function Subnavigation({ entry, navigation }: SubnavigationProps) {
  const router = useRouter();
  const [forceClosed, setForceClosed] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isTopicHovered, setIsTopicHovered] = useState(false);

  const handleTopicMouseEnter = () => {
    setIsTopicHovered(true);
  };

  const handleTopicMouseLeave = () => {
    setIsTopicHovered(false);
  };

  const stickNavbar = () => {
    const windowHeight = window.scrollY;
    windowHeight > 50 ? setIsSticky(true) : setIsSticky(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    const body = document.querySelector("body");
    if (body) {
      menuOpen
        ? body.classList.add("headroom--blog-menu-active")
        : body.classList.remove("headroom--blog-menu-active");
    }

    const handleRouteChangeStart = () => {
      setForceClosed(true);
    };
    const handleRouteChangeComplete = () => {
      setForceClosed(false);
      setMenuOpen(false);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router, menuOpen]);

  const nodeClassName = "type-epsilon link-underline-animated-sm";
  const topicClassName =
    "text-[15px] pl-5 lg:pl-0 lg:type-epsilon lg:uppercase  lg:link-underline-animated-sm";
  const nodeClassNameLg =
    "type-epsilon text-base 2lg:text-lg rounded-md inline-block transition px-3 py-1 hover:bg-red-100/70 focus:bg-red-100/70";
  const topicClassNameLg =
    "type-pr-zeta link-underline-animated-sm text-[15px]";
  // const nodeLiClassNameLg = "-mb-[22px] pb-[22px]";

  const navigationItem = navigation.navigationCollection
    ? navigation.navigationCollection.items[0]
    : null;

  if (!navigationItem) {
    return null;
  }

  const homeTitle = navigationItem.homeTitle as string;
  const homeSubtitle = navigationItem.homeSubtitle as string;
  const homeUrl = navigationItem.homeUrl
    ? contentfulEntryUrl(navigationItem.homeUrl)
    : "/blog/";
  const ctaIcon = navigationItem.ctaIcon;
  const ctaUrl = navigationItem.ctaUrl?.slug as string;
  const ctaExternalUrl = navigationItem.ctaExternalUrl as string;
  const ctaTitle = navigationItem.ctaTitle as string;
  const popularTopicsCollection = navigationItem.popularTopicsCollection;
  const allTopicsCollection = navigationItem.allTopicsCollection;

  // const nodes = popularTopicsCollection?.items as
  //   | PopularTopicsCollectionItem[]
  //   | undefined;
  const topics = allTopicsCollection?.items as
    | AllTopicsCollectionItem[]
    | undefined;

  let ctaUrlValue: string | null = null;

  if (ctaUrl) {
    ctaUrlValue = ctaUrl;
  } else if (ctaExternalUrl) {
    ctaUrlValue = ctaExternalUrl;
  }

  // const renderNodes = (
  //   items: PopularTopicsCollectionItem[],
  //   className = "",
  //   liClassName = ""
  // ) => {
  //   return items.map((item, index) => {
  //     return (
  //       <SubnavigationItem
  //         key={item.sys.id}
  //         url={contentfulEntryUrl(item)}
  //         title={item.title}
  //         subnodes={filterNull(item.featuredBlogPostsCollection?.items)}
  //         className={className}
  //         liClassName={liClassName}
  //         forceClosed={forceClosed}
  //       />
  //     );
  //   });
  // };

  const renderTopics = (
    items: AllTopicsCollectionItem[],
    className = "",
    liClassName = "py-4 lg:py-0 lg:col-span-4 lg:w-[200px] lg:py-3.5",
    liClassNameBorder = "py-4 lg:py-0 lg:col-span-4 lg:w-[200px] lg:py-3.5 border-t border-[#D9D9D9]"
  ) => {
    return items.map((item, index) => {
      if (index >= 3) {
        return (
          <SubnavigationItem
            key={item.sys.id}
            url={contentfulEntryUrl(item)}
            title={item.title}
            className={className}
            liClassName={liClassNameBorder}
            onClick={() => handleTopicMouseLeave()}
          />
        );
      } else {
        return (
          <SubnavigationItem
            key={item.sys.id}
            url={contentfulEntryUrl(item)}
            title={item.title}
            className={className}
            liClassName={liClassName}
            onClick={() => handleTopicMouseLeave()}
          />
        );
      }
    });
  };

  const Header = entry?.__typename == "Homepage" ? "h1" : "div";

  const imageDimensions = {
    width: ctaIcon?.width || 60,
    height: ctaIcon?.height || 41
  };

  const transforms: ContentfulImageUrlTransforms = {
    width: imageDimensions.width,
    height: imageDimensions.height
  };

  return (
    <div
      className={`z-10 w-full bg-white ${
        isSticky ? "fixed inset-x-0 top-[80px] z-10 lg:top-[92px]" : ""
      }`}
    >
      <BlogContainer>
        <div className="flex items-center border-b border-red-500">
          {/* mobile */}
          <div className="relative size-full items-center justify-between pt-6 lg:hidden">
            <SmartLink href={homeUrl} className="flex flex-col items-center">
              <span
                className={`type-beta block text-red-500 ${
                  isSticky ? "text-[20px]" : "text-5xl"
                }`}
              >
                {homeTitle}
              </span>
              <span
                className={`type-beta relative text-pr-base text-pr-dark-gray-500 ${
                  isSticky ? "hidden" : "block"
                }`}
              >
                {homeSubtitle}
              </span>
            </SmartLink>

            <div
              className={`flex items-center justify-between ${
                isSticky ? "h-12" : "h-[72px]"
              }`}
            >
              <Popover>
                <>
                  <Button
                    className="relative overflow-hidden pr-5 text-lg"
                    onClick={() => setMenuOpen(!menuOpen)}
                    ariaControls="menu"
                    ariaLabel="menu_toggle"
                    ariaExpanded={menuOpen}
                  >
                    <span>All Topics</span>
                    <svg
                      className={`absolute right-0 top-1/2 -translate-y-1/2 stroke-current transition ${
                        menuOpen ? "-rotate-180" : ""
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                    >
                      <path
                        d="M1 1L7.0438 7L13 1"
                        stroke="#30323B"
                        strokeLinecap="round"
                      />
                    </svg>
                    {/* <Chevron
                      className={`absolute right-0 top-1/2 h-2 w-2.5 -translate-y-1/2 stroke-current transition ${
                        menuOpen ? "-rotate-180" : ""
                      }`}
                    /> */}
                  </Button>
                  <Transition
                    show={menuOpen}
                    id="menu"
                    aria-labelledby="menu_toggle"
                    enter="transition duration-100 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Panel
                      className={`fixed inset-x-0 -bottom-px z-10 w-full space-y-6 overflow-auto bg-white pb-10 pl-5 pr-[50px] shadow-xl ${
                        isSticky ? "top-[174px]" : "top-[214px]"
                      }`}
                    >
                      {/* {nodes && (
                        <div className="space-y-4 pt-5">
                          <p className="type-eta text-gray-400">Popular</p>
                          <ul className="grid grid-cols-2 gap-6 sm:grid-cols-3">
                            {renderNodes(nodes, nodeClassName)}
                          </ul>
                        </div>
                      )} */}
                      {topics && (
                        <div className="space-y-4 pt-1">
                          {/* <p className="type-eta text-gray-400">Explore Topics</p> */}
                          <ul className="flex flex-col divide-y">
                            {renderTopics(topics, topicClassName)}
                          </ul>
                        </div>
                      )}
                      {/* {ctaUrlValue && ctaTitle && (
                        <SmartLink
                          href={ctaUrlValue}
                          className="button-solid-red w-full sm:w-auto"
                        >
                          {ctaTitle}
                        </SmartLink>
                      )} */}
                    </Popover.Panel>
                  </Transition>
                </>
              </Popover>
              <div className="flex items-center gap-2">
                {ctaIcon && (
                  <figure className="relative mx-auto size-10">
                    <ContentfulImage
                      image={ctaIcon}
                      layout="fill"
                      className="object-contain"
                    />
                  </figure>
                )}
                {ctaUrlValue && ctaTitle && (
                  <SmartLink
                    href={ctaExternalUrl}
                    className=" text-lg text-red-500"
                  >
                    {ctaTitle}
                  </SmartLink>
                )}
              </div>
            </div>
          </div>
          {/* desktop */}
          <div
            className={`hidden size-full items-center justify-between lg:flex ${
              isSticky ? "py-5" : "py-6"
            }`}
          >
            {topics && (
              <div
                className="relative py-2"
                onMouseOver={() => handleTopicMouseEnter()}
                onMouseOut={() => handleTopicMouseLeave()}
              >
                <div>
                  <div className="relative inline-block whitespace-nowrap rounded-md pl-[30px]">
                    <span className="pb-5 text-pr-lg">Explore Topics</span>
                    <div
                      className={`w-full 
                    ${isTopicHovered ? "block" : "hidden"}`}
                    >
                      <div className="clip-path absolute right-[calc(50%)] top-[calc(110%)] z-40 translate-x-1/2 px-2">
                        <svg
                          fill="white"
                          id="triangle"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18px"
                          height="17px"
                          viewBox="0 0 100 100"
                        >
                          <polygon points="50 15, 100 100, 0 100" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className={`${isTopicHovered ? "block" : "hidden"}`}>
                    <div className="absolute top-[calc(129%)] z-30 rounded-[20px] bg-white px-10 py-[1.625rem] shadow-navBox">
                      <ul className="grid w-[680px] grid-cols-12 gap-x-10">
                        {renderTopics(topics, topicClassNameLg)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="relative pr-5 xl:pr-6">
              <SmartLink href={homeUrl}>
                <Header>
                  <span
                    className={`type-beta self-end whitespace-nowrap text-red-500 ${
                      isSticky ? "text-2xl xl:text-4xl" : "text-3xl xl:text-5xl"
                    } `}
                  >
                    {homeTitle}
                  </span>
                </Header>
                <span
                  className={`type-beta whitespace-nowrap text-2xs font-normal leading-6 text-pr-dark-gray-500 xl:text-base ${
                    isSticky ? "hidden" : "block"
                  }`}
                >
                  {homeSubtitle}
                </span>
              </SmartLink>
            </div>

            {/* {nodes && (
              <div className="flex flex-1 flex-col justify-end px-2 pb-1.5 xl:px-3">
                <p className="type-eta mx-3 mb-1 text-gray-400">Popular</p>
                <ul className="flex space-x-1 xl:space-x-5">
                  {renderNodes(nodes, nodeClassNameLg, nodeLiClassNameLg)}
                </ul>
              </div>
            )} */}

            <div className="flex items-center gap-2 xl:pr-[30px]">
              {ctaIcon && (
                <figure className="relative mx-auto h-10 w-16">
                  <ContentfulImage
                    image={ctaIcon}
                    layout="fill"
                    className="object-contain"
                  />
                </figure>
              )}

              {ctaUrlValue && ctaTitle && (
                <SmartLink
                  href={ctaExternalUrl}
                  className=" w-full text-lg text-red-500"
                >
                  {ctaTitle}
                </SmartLink>
              )}
            </div>
          </div>
        </div>
      </BlogContainer>
    </div>
  );
}

export default Subnavigation;
