import {
  DTRDynamicSectionSwiperCollectionItems,
  DTRDynamicSectionSwiperSlidesCollection,
  DTRDynamicSectionSwiperSlidesCollectionItems,
  DynamicTrendReportCardWithIcon,
  DynamicTrendReportImageWithText,
  DynamicTrendReportMediaWithText,
  DynamicTrendReportQuote
} from "@src/lib/services/server/contentful";

import * as React from "react";
import Image from "next/image";
import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { PlyrVideo } from "@src/components/PlyrVideo";

import Arrow from "@/img/icons/arrow.svg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type SlideContentCollection = {
  slideContentCollection: {
    items: DTRDynamicSectionSwiperSlidesCollectionItems[];
  } | null;
};

export type DtrDynamicSwiperProps = {
  id: string;
  content: DTRDynamicSectionSwiperCollectionItems;
};

const DtrDynamicSwiper = ({ id, content }: DtrDynamicSwiperProps) => {
  const { items: swiperSlidesCollection } = content as {
    items: (DTRDynamicSectionSwiperSlidesCollection & SlideContentCollection)[];
  };

  const formatTextWithLineBreaks = (text: string) =>
    text.includes("\n")
      ? text.split("\n").map((line, i) => <p key={i}>{line}</p>)
      : text;

  const renderMediaWithText = (
    {
      sys,
      image,
      title,
      description,
      vimeoVideo,
      innerQuote,
      mediaLocation: mediaOnTop
    }: DynamicTrendReportMediaWithText,
    index: number,
    swiperColor: string,
    textColor: string
  ) => {
    const renderMedia = () =>
      vimeoVideo ? (
        <div className="relative mx-auto aspect-video">
          <PlyrVideo video={vimeoVideo} />
        </div>
      ) : (
        <div className="relative mx-auto aspect-video">
          <Image
            src={image?.url ?? ""}
            alt={image?.title ?? ""}
            layout="fill"
          />
        </div>
      );

    const renderContent = () => (
      <div className="mx-auto p-6 text-left md:p-12">
        <h3 className="mb-3 text-center text-3xl text-pr-dark-gray-600 md:text-left">
          {title}
        </h3>
        {description && (
          <div className="mb-12 flex flex-col gap-3 text-pr-light-gray-500 last:mb-3">
            {formatTextWithLineBreaks(description)}
          </div>
        )}
        {innerQuote && (
          <div
            className={`text-balance rounded-3xl rounded-bl-none ${swiperColor} ${textColor} p-12`}
          >
            <p className="mb-6">{innerQuote.quote}</p>
            <div className="flex items-center gap-3">
              <Image
                className="rounded-3xl rounded-br-none"
                src={innerQuote.icon?.url ?? ""}
                alt={innerQuote.icon?.title ?? ""}
                height={50}
                width={50}
              />
              <p>{innerQuote.author}</p>
            </div>
          </div>
        )}
      </div>
    );

    return (
      <div
        key={sys.id}
        className="overflow-hidden rounded-3xl border-2 border-black lg:mx-16"
      >
        {mediaOnTop ? (
          <>
            {renderMedia()}
            <div className="border-t-2 border-black">{renderContent()}</div>
          </>
        ) : (
          <>
            <div className="border-b-2 border-black">{renderContent()}</div>
            {renderMedia()}
          </>
        )}
      </div>
    );
  };

  const renderImageWithText = (
    {
      sys,
      image,
      title,
      description,
      mediaLocation: mediaOnLeft
    }: DynamicTrendReportImageWithText,
    index: number,
    swiperColor: string,
    textColor: string
  ) => {
    const renderImage = () => (
      <div className="relative aspect-square min-h-80 basis-1/3">
        <Image src={image?.url ?? ""} alt={image?.title ?? ""} layout="fill" />
      </div>
    );

    const renderContent = () => (
      <div className="basis-2/3 text-left">
        <h3 className="mb-3 text-center text-3xl text-pr-dark-gray-600 md:text-left">
          {title}
        </h3>
        {description && (
          <div className="flex flex-col gap-3 text-pr-light-gray-500 last:mb-3">
            {formatTextWithLineBreaks(description)}
          </div>
        )}
      </div>
    );

    return (
      <div
        key={sys.id}
        className="flex flex-col items-center gap-6 md:flex-row"
      >
        {mediaOnLeft ? (
          <>
            {renderImage()}
            {renderContent()}
          </>
        ) : (
          <>
            {renderContent()}
            {renderImage()}
          </>
        )}
      </div>
    );
  };

  const renderCardWithIcon = (
    { sys, title, description, icon }: DynamicTrendReportCardWithIcon,
    index: number,
    swiperColor: string,
    textColor: string
  ) => {
    const backgroundColor = [
      "bg-pr-green-500",
      "bg-pr-teal-200",
      "bg-pr-teal-500",
      "bg-pr-green-100"
    ];

    return (
      <div
        key={sys.id}
        className={`flex flex-1 basis-2/5 flex-col items-center gap-3 rounded-3xl p-6 text-center md:flex-row md:text-left ${backgroundColor[index]}`}
      >
        <div className="grow">
          <h3 className="text-3xl font-bold text-pr-dark-gray-600">{title}</h3>
          {description && (
            <div className="mb-3">{formatTextWithLineBreaks(description)}</div>
          )}
        </div>
        <div className="shrink-0">
          <Image
            src={icon?.url ?? ""}
            alt={icon?.title ?? ""}
            height={100}
            width={100}
          />
        </div>
      </div>
    );
  };

  const renderQuote = (
    { sys, quote, author, icon }: DynamicTrendReportQuote,
    index: number,
    swiperColor: string,
    textColor: string
  ) => (
    <div
      key={sys.id}
      className={`w-full text-balance rounded-3xl rounded-bl-none p-12 text-left ${textColor} ${swiperColor}`}
    >
      <p className="mb-6">{quote}</p>
      <div className="flex items-center justify-end gap-3">
        <Image
          className="rounded-3xl rounded-br-none"
          src={icon?.url ?? ""}
          alt={icon?.title ?? ""}
          height={50}
          width={50}
        />
        <p>{author}</p>
      </div>
    </div>
  );

  const renderSlideItem = (
    slideItem: DTRDynamicSectionSwiperSlidesCollectionItems,
    index: number,
    backgroundColor: string,
    textColor: string
  ) => {
    switch (slideItem.__typename) {
      case "DynamicTrendReportMediaWithText":
        return renderMediaWithText(
          slideItem,
          index,
          backgroundColor,
          textColor
        );
      case "DynamicTrendReportImageWithText":
        return renderImageWithText(
          slideItem,
          index,
          backgroundColor,
          textColor
        );
      case "DynamicTrendReportCardWithIcon":
        return renderCardWithIcon(slideItem, index, backgroundColor, textColor);
      case "DynamicTrendReportQuote":
        return renderQuote(slideItem, index, backgroundColor, textColor);
      default:
        return null;
    }
  };

  const renderSwiperInstance = () => {
    const swiperPallete: { [key: string]: string } = {
      "fun-facts": "bg-pr-green-500",
      technology: "bg-red-600",
      operations: "bg-pr-teal-200",
      design: "bg-red-600",
      predictions: "bg-pr-green-500"
    };

    const getTextColor = (id: string) =>
      ["bg-pr-green-500", "bg-pr-teal-200"].includes(swiperPallete[id])
        ? "text-black"
        : "text-white";

    const bulletClass: { [key: string]: string } = {
      "fun-facts": "swiper-pagination-bullet-dynamic-trend-green",
      predictions: "swiper-pagination-bullet-dynamic-trend-green",
      technology: "swiper-pagination-bullet-dynamic-trend-red",
      design: "swiper-pagination-bullet-dynamic-trend-red",
      operations: "swiper-pagination-bullet-dynamic-trend-teal"
    };

    const bulletActiveClass: { [key: string]: string } = {
      "fun-facts": "swiper-pagination-bullet-active-dynamic-trend-green",
      predictions: "swiper-pagination-bullet-active-dynamic-trend-green",
      technology: "swiper-pagination-bullet-active-dynamic-trend-red",
      design: "swiper-pagination-bullet-active-dynamic-trend-red",
      operations: "swiper-pagination-bullet-active-dynamic-trend-teal"
    };

    return (
      <>
        <div className="absolute right-0 top-0 z-10 flex flex-wrap items-center justify-end gap-px">
          <button
            className={`swiper-button-prev-${id} flex items-center justify-center rounded-l-full ${
              swiperPallete[id]
            } px-2.5 py-3.5 ${getTextColor(id)} transition md:px-4 md:py-5`}
          >
            <Arrow className="h-3 w-5 rotate-180 fill-current" />
          </button>
          <button
            className={`swiper-button-next-${id} flex items-center justify-center rounded-r-full ${
              swiperPallete[id]
            } px-2.5 py-3.5 ${getTextColor(id)} transition md:px-4 md:py-5`}
          >
            <Arrow className="h-3 w-5 fill-current" />
          </button>
        </div>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          pagination={{
            clickable: true,
            bulletClass: bulletClass[id],
            bulletActiveClass: bulletActiveClass[id],
            renderBullet: (_, className) => `<span class="${className}"></span>`
          }}
          navigation={{
            prevEl: `.swiper-button-prev-${id}`,
            nextEl: `.swiper-button-next-${id}`
          }}
          className="dynamic-trend-swiper swiper-pagination-top"
          spaceBetween={16}
          slidesPerView={1}
          autoHeight={true}
          loop={true}
        >
          {swiperSlidesCollection.map((slides, index) => (
            <SwiperSlide
              key={index}
              className="flex flex-col flex-wrap gap-4 pt-20 md:flex-row"
            >
              {slides.slideContentCollection?.items.map(
                (
                  slideItem: DTRDynamicSectionSwiperSlidesCollectionItems,
                  index: number
                ) =>
                  renderSlideItem(
                    slideItem,
                    index,
                    swiperPallete[id],
                    getTextColor(id)
                  )
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    );
  };

  return (
    <div className="mx-auto max-w-screen-xl px-5">
      <div className="relative">{renderSwiperInstance()}</div>
    </div>
  );
};

export { DtrDynamicSwiper };
