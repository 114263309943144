import type {
  DynamicTrendReportCollection as ImportedDynamicTrendReportCollection,
  DynamicTrendReportDynamicSectionsCollection,
  DynamicTrendReportHeroSection
} from "@src/lib/services/server/contentful";

import * as React from "react";

import { DtrDynamicSections, DtrHeroSection } from "@src/components";

export interface DynamicTrendReportCollection
  extends ImportedDynamicTrendReportCollection {
  heroSection?: DynamicTrendReportHeroSection | null;
  dynamicSectionsCollection?: DynamicTrendReportDynamicSectionsCollection | null;
}

export interface DynamicTrendReportProps {
  entry: DynamicTrendReportCollection;
}

const DynamicTrendReport = ({ entry }: DynamicTrendReportProps) => {
  const { heroSection, dynamicSectionsCollection } = entry;

  return (
    <>
      {heroSection && <DtrHeroSection content={heroSection} />}
      {dynamicSectionsCollection && (
        <DtrDynamicSections content={dynamicSectionsCollection} />
      )}
    </>
  );
};

export { DynamicTrendReport };
