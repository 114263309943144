import * as React from "react";

import { ProductHomepageHeroSlider } from "@src/lib/services/server/contentful";
import {
  ProductContainer,
  ProductHeroSlideMotionControls
} from "@src/components";
import { ProductHeroSlide } from "@src/components/ProductHeroSlide";

// Import Swiper React components
import { Autoplay, Navigation, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css";

export type ProductHeroHomepageProps = {
  heroSlider?: ProductHomepageHeroSlider | null;
};

const ProductHeroHomepage = ({
  heroSlider
}: Readonly<ProductHeroHomepageProps>) => {
  const heroSliderSlidesItems = heroSlider?.heroSlidesCollection?.items;

  return (
    <div className="relative overflow-hidden bg-white pt-5 md:pt-8">
      <ProductContainer>
        <Swiper
          id="hero-slider"
          slidesPerView={1}
          spaceBetween={48}
          loop={true}
          modules={[Autoplay, Navigation, Keyboard]}
          centerInsufficientSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }}
          keyboard={{
            enabled: true
          }}
        >
          {heroSliderSlidesItems?.map((slideContent, index) => (
            <SwiperSlide
              id={`hero-slide-${index + 1}`}
              key={slideContent?.slideTitle}
              className="flex flex-col-reverse gap-5 md:flex-row md:gap-8"
            >
              <ProductHeroSlide
                index={index}
                slideTitle={slideContent?.slideTitle}
                slideDescription={slideContent?.slideDescription}
                slideButtonTitle={slideContent?.slideButtonTitle}
                slideButtonLink={slideContent?.slideButtonLink}
                slideImage={slideContent?.slideImage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <ProductHeroSlideMotionControls />
      </ProductContainer>
    </div>
  );
};

export { ProductHeroHomepage };
