import * as React from "react";
import { ComparisonTable } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText } from "@src/components";

export type ProductComparisonTableBlockProps = {
  table: ComparisonTable;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductComparisonTableBlock({
  table
}: ProductComparisonTableBlockProps) {
  const tableHeading = table.heading;
  const tableText = table.text?.json as Document | undefined;
  const tableOrientation = table.orientation
    ? table.orientation.toLowerCase().split(" ").join("")
    : null;

  return (
    <div>
      <ProductContainer>
        <div className="space-y-4 lg:space-y-8">
          {tableHeading && (
            <h2 className="type-pr-epsilon text-center xl:text-pr-7xl">
              {returnLineBreaks(tableHeading)}
            </h2>
          )}
          {tableText && (
            <div
              className={`comparison-table block overflow-x-auto ${
                tableOrientation == "center" ? "comparison-table-centered" : ""
              }`}
            >
              <RichText text={tableText} />
            </div>
          )}
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductComparisonTableBlock;
