/* eslint-disable tailwindcss/no-contradicting-classname */
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Document } from "@contentful/rich-text-types";
import IframeResizer from "iframe-resizer-react";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import {
  ProductContainer,
  RichText,
  ContentfulImage,
  MarketoForm
} from "@src/components";
import { keyMapping } from "@src/lib/utils";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { IContentfulImage } from "@src/lib/types";
import Logo from "@/img/icons/logo.svg";

export type ProductCtaFormProps = {
  heading?: string | null;
  headingTag?: string | null;
  headingStyle?: string | null;
  text?: Document | null;
  textStyle?: string | null;
  image?: IContentfulImage | null;
  backgroundStyle?: string | null;
  orientation?: string | null;
  displayLogo?: boolean | null;
  formKey?: string | null;
  source?: string | null;
  formId?: string | null;
  formTheme?: string | null;
  formHeading?: string | null;
  finePrint?: Document | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

const toTitleCase = (str: string) => {
  str = str.replace("/", "");
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const processGa = (str: string) => {
  return str.split(".").slice(-2).join(".");
};

export function ProductCtaForm({
  heading,
  headingTag,
  headingStyle = "medium",
  text,
  textStyle = "large",
  image,
  backgroundStyle = "white",
  orientation = "onecolumnform",
  displayLogo = true,
  formKey = "productctademorequest",
  source,
  formId,
  formTheme = "onecolumnform",
  formHeading,
  finePrint
}: ProductCtaFormProps) {
  const router = useRouter();
  const cookies = parseCookies();
  const queryParsed = router.query;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hiddenFields: string[] = [];
  const [isLoaded, setIsLoaded] = useState(false);

  const dimensions = {
    width: image?.width || 1648,
    height: image?.height || 1470
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  // set hidden fields from params
  for (const [key, value] of Object.entries(queryParsed)) {
    if (Object.keys(keyMapping).includes(key)) {
      const valueString = value as string;
      hiddenFields.push(`${key}=${encodeURIComponent(valueString)}`);
    }
  }

  // set hidden fields from cookies
  for (const [key, value] of Object.entries(cookies)) {
    if (Object.keys(keyMapping).includes(key)) {
      hiddenFields.push(
        `${keyMapping[key]}=${
          key == "_ga"
            ? processGa(cookies[key])
            : encodeURIComponent(cookies[key])
        }`
      );
    }
  }

  // product demo request form in pardot: https://pi.pardot.com/form/read/id/9768
  // product demo form: https://go.getbento.com/l/667503/2022-11-18/4ydl1

  // can remove this after switchover to marketo because source will no longer be a thing
  const defaultFormKey: string = "productctademorequest";
  const formSrcs: { [key: string]: string } = {
    productctademorequest: "https://go.getbento.com/l/667503/2022-11-18/4ydl1",
    productctaform: source as string
  };
  const formSrc = formSrcs[formKey ? formKey : defaultFormKey];
  const [src, setSrc] = useState(formSrc);

  useEffect(() => {
    // set Submission_URL
    hiddenFields.push(
      `Submission_URL=${encodeURIComponent(window.location.href)}`
    );

    // set Partnership
    if (formKey == "productctademorequest") {
      hiddenFields.push(`Partnership=${toTitleCase(window.location.pathname)}`);
    }

    setSrc(
      hiddenFields.length
        ? `${formSrc}?${hiddenFields.join("&")}`
        : `${formSrc}`
    );
  }, [hiddenFields, formSrc, formKey]);

  // bailing out if iframe src is not valid, can remove this after switchover to marketo
  if (source && !source.includes("go.getbento.com")) {
    return null;
  }

  const defaultOrientation: string = "onecolumnform";
  const defaultHeadingStyle: string = "medium";
  const defaultTextStyle: string = "large";

  const classNames: { [key: string]: string } = {
    onecolumnform: "",
    twocolumnform: "lg:grid-cols-12"
  };

  const textColClassNames: { [key: string]: string } = {
    onecolumnform: "",
    twocolumnform: "lg:col-span-5"
  };

  const formColClassNames: { [key: string]: string } = {
    onecolumnform: "lg:px-20",
    twocolumnform: "lg:col-span-7 lg:px-5"
  };

  const formWrapClassNames: { [key: string]: string } = {
    onecolumnform: "px-5 lg:px-11 lg:pb-16",
    twocolumnform: "px-8"
  };

  const headingClassNames: { [key: string]: string } = {
    large: "type-pr-beta lg:type-pr-alpha",
    medium: "type-pr-beta",
    small: "type-pr-gamma"
  };

  const textClassNames: { [key: string]: string } = {
    large: "lg:prose-pr-lg xl:prose-pr-xl",
    medium: "prose-p:text-[18px]"
  };

  let className,
    textColClassName,
    formColClassName,
    formWrapClassName = "";

  if (formTheme) {
    className = classNames[formTheme ? formTheme : defaultOrientation];
    textColClassName =
      textColClassNames[formTheme ? formTheme : defaultOrientation];
    formColClassName =
      formColClassNames[formTheme ? formTheme : defaultOrientation];
    formWrapClassName =
      formWrapClassNames[formTheme ? formTheme : defaultOrientation];
  } else {
    className = classNames[orientation ? orientation : defaultOrientation];
    textColClassName =
      textColClassNames[orientation ? orientation : defaultOrientation];
    formColClassName =
      formColClassNames[orientation ? orientation : defaultOrientation];
    formWrapClassName =
      formWrapClassNames[orientation ? orientation : defaultOrientation];
  }
  const headingClassName =
    headingClassNames[headingStyle ? headingStyle : defaultHeadingStyle];
  const textClassName =
    textClassNames[textStyle ? textStyle : defaultTextStyle];

  return (
    <div className="overflow-hidden py-8 md:py-16 lg:py-[100px]">
      {backgroundStyle != "white" && (
        <div className="relative">
          <svg
            viewBox="0 0 97 655"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-0 w-1/4 md:hidden"
          >
            <path
              d="M23.7216 511.13C36.8182 511.13 47.4414 521.753 47.4414 534.85C47.4414 547.946 36.8182 558.57 23.7216 558.57C10.6249 558.57 0.00162545 547.946 0.0016266 534.85C0.00162774 521.753 10.6249 511.13 23.7216 511.13Z"
              className="fill-[#66C080] opacity-20"
            />
            <path
              d="M48.1914 559.316L96.0047 559.316C96.0047 585.723 74.599 607.129 48.1914 607.129L48.1914 559.316Z"
              className="fill-[#FFEABD] opacity-[.35]"
            />
            <path
              d="M96.0039 511.503L48.1906 511.503C48.1906 485.096 69.5963 463.69 96.0039 463.69L96.0039 511.503Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M47.8164 606.757L47.8164 654.57C21.4088 654.57 0.00308226 633.165 0.00308342 606.757L47.8164 606.757Z"
              className="fill-[#E0ECD6]"
            />
            <path
              d="M23.7216 606.755L0.00162245 606.755L0.00162453 559.316L23.7216 559.316C36.8182 559.316 47.4414 569.935 47.4414 583.027C47.4414 596.136 36.8182 606.755 23.7216 606.755Z"
              className="fill-[#FCD2C9]"
            />
            <path
              d="M48.1914 463.316C48.1914 450.116 37.5681 439.409 24.4715 439.409C11.3748 439.409 0.751626 450.116 0.751625 463.316L48.1914 463.316Z"
              className="fill-[#E0ECD6]"
            />
            <path
              d="M48.1914 439.407C48.1914 426.208 37.4846 415.501 24.2848 415.501C11.085 415.501 0.378085 426.208 0.378084 439.407L48.1914 439.407Z"
              className="fill-[#E0ECD6]"
            />
            <path
              d="M73.2799 190.537C60.1832 190.537 49.5601 179.914 49.5601 166.818C49.5601 153.721 60.1832 143.098 73.2799 143.098C86.3765 143.098 96.9997 153.721 96.9997 166.818C96.9997 179.914 86.3765 190.537 73.2799 190.537Z"
              className="fill-[#66C080] opacity-20"
            />
            <path
              d="M49.187 142.723L49.187 94.9094C75.5946 94.9094 97.0002 116.315 97.0002 142.723L49.187 142.723Z"
              className="fill-[#76CDD6] opacity-[.35]"
            />
            <path
              d="M48.812 95.2812L0.998784 95.2812C0.998787 68.8737 22.4045 47.468 48.812 47.468L48.812 95.2812Z"
              className="fill-[#FFEABD] opacity-[.35]"
            />
            <path
              d="M0.999512 143.098H48.8127C48.8127 169.505 27.4071 190.911 0.999512 190.911V143.098Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M49.187 47.8418L49.187 0.0285694C75.5946 0.0285683 97.0002 21.4342 97.0002 47.8418L49.187 47.8418Z"
              className="fill-[#E0ECD6]"
            />
            <path
              d="M73.2799 47.8416L96.9997 47.8416L96.9997 95.2812L73.2799 95.2812C60.1832 95.2812 49.5601 84.662 49.5601 71.5703C49.5601 58.4608 60.1832 47.8416 73.2799 47.8416Z"
              className="fill-[#FCD2C9]"
            />
            <path
              d="M48.812 191.281C48.812 204.481 59.4353 215.188 72.5319 215.188C85.6285 215.188 96.2517 204.481 96.2517 191.281H48.812Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M48.812 215.191C48.812 228.391 59.5188 239.098 72.7186 239.098C85.9183 239.098 96.6252 228.391 96.6252 215.191H48.812Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M24.7194 94.9102C37.816 94.9102 48.4392 105.617 48.4392 118.817C48.4392 132.016 37.816 142.723 24.7194 142.723C11.6228 142.723 0.999512 132.016 0.999512 118.817C0.999512 105.599 11.6228 94.9102 24.7194 94.9102Z"
              className="fill-[#FFEABD] opacity-[.8]"
            />
          </svg>

          <svg
            viewBox="0 0 514 640"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute -top-16 right-0 hidden w-[32%] md:block lg:top-[-100px]"
          >
            <path
              d="M450.5 509.999C415.439 509.999 387 481.56 387 446.499C387 411.438 415.439 382.999 450.5 382.999C485.561 382.999 514 411.438 514 446.499C514 481.56 485.561 509.999 450.5 509.999Z"
              className="fill-[#66C080] opacity-20"
            />
            <path
              d="M386 381.999L386 253.999C456.695 253.999 514 311.304 514 381.999L386 381.999Z"
              className="fill-[#76CDD6] opacity-[.35]"
            />
            <path
              d="M385 254.999L257 254.999C257 184.304 314.305 126.999 385 126.999L385 254.999Z"
              className="fill-[#FFEABD] opacity-[.35]"
            />
            <path
              d="M257 382.999H385C385 453.694 327.695 510.999 257 510.999V382.999Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M386 127.999L386 -0.000976562C456.695 -0.000979653 514 57.3038 514 127.999L386 127.999Z"
              className="fill-[#E0ECD6]"
            />
            <path
              d="M450.5 127.999L514 127.999L514 254.999L450.5 254.999C415.439 254.999 387 226.57 387 191.523C387 156.428 415.439 127.999 450.5 127.999Z"
              className="fill-[#FCD2C9]"
            />
            <path
              d="M385 511.999C385 547.336 413.439 575.999 448.5 575.999C483.561 575.999 512 547.336 512 511.999H385Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M385 575.999C385 611.336 413.663 639.999 449 639.999C484.337 639.999 513 611.336 513 575.999H385Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M320.5 253.999C355.561 253.999 384 282.662 384 317.999C384 353.336 355.561 381.999 320.5 381.999C285.439 381.999 257 353.336 257 317.999C257 282.614 285.439 253.999 320.5 253.999Z"
              className="fill-[#FFEABD] opacity-[.8]"
            />
            <path
              d="M63.5001 256C98.5609 256 127 284.439 127 319.5C127 354.561 98.5609 383 63.5001 383C28.4394 383 6.12912e-06 354.561 9.19422e-06 319.5C1.22593e-05 284.439 28.4394 256 63.5001 256Z"
              className="fill-[#66C080] opacity-20"
            />
            <path
              d="M129 385L257 385C257 455.695 199.695 513 129 513L129 385Z"
              className="fill-[#FFEABD] opacity-[.35]"
            />
            <path
              d="M257 257L129 257C129 186.305 186.305 129 257 129L257 257Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M128 512L128 640C57.3048 640 -2.80386e-06 582.695 2.86323e-07 512L128 512Z"
              className="fill-[#E0ECD6]"
            />
            <path
              d="M63.5001 512L-1.90848e-06 512L3.64287e-06 385L63.5001 385C98.5609 385 127 413.429 127 448.476C127 483.571 98.5609 512 63.5001 512Z"
              className="fill-[#FCD2C9]"
            />
            <path
              d="M129 128C129 92.6631 100.561 64 65.4999 64C30.4391 64 2.00001 92.6631 2 128L129 128Z"
              className="fill-[#FFEABD]"
            />
            <path
              d="M129 64C129 28.6632 100.337 -5.52603e-07 65.0001 -3.64185e-06C29.6633 -6.73109e-06 1 28.6632 1 64L129 64Z"
              className="fill-[#FFEABD]"
            />
          </svg>
        </div>
      )}

      <ProductContainer>
        <div
          className={`relative grid gap-6 md:grid-cols-2 lg:gap-0 ${className}`}
        >
          <div
            className={`order-2 space-y-3 md:order-1 lg:space-y-9 ${textColClassName}`}
          >
            {heading && headingTag == "H1" && (
              <h1 className={`${headingClassName}`}>
                {returnLineBreaks(heading)}
              </h1>
            )}

            {heading && headingTag != "H1" && (
              <h2 className={`${headingClassName}`}>
                {returnLineBreaks(heading)}
              </h2>
            )}

            {image && (
              <figure className="">
                <ContentfulImage image={image} transforms={transforms} />
              </figure>
            )}

            {text && (
              <div
                className={`prose prose-pr prose-pr-list-checkbox ${textClassName}`}
              >
                <RichText text={text} />
              </div>
            )}
          </div>

          <div
            className={`order-1 -mx-2.5 md:order-2 md:mx-0 ${formColClassName} ${
              isLoaded || (src && !formId)
                ? "opacity-100 transition delay-300 ease-in-out"
                : "opacity-0"
            }`}
          >
            <div
              className={`rounded-2xl bg-white py-8 drop-shadow-lg md:rounded-3xl ${formWrapClassName}`}
            >
              {displayLogo && (
                <div className="mb-5 flex justify-center border-b-2 border-gray-200 pb-5">
                  <Logo className="h-[34px] w-[156px] md:h-[38px] md:w-[186px]" />
                </div>
              )}
              {formId && (
                <div className="py-5 lg:px-8">
                  {formHeading && (
                    <div className="pb-5 text-pr-8xl">{formHeading}</div>
                  )}
                  <MarketoForm
                    formId={formId}
                    formTheme={formTheme}
                    setIsLoaded={setIsLoaded}
                  />
                  {finePrint && (
                    <div className="fine-print">
                      <RichText text={finePrint} />
                    </div>
                  )}
                </div>
              )}
              {src && !formId && (
                <IframeResizer src={src} style={{ width: "100%" }} />
              )}
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductCtaForm;
