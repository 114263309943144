import * as React from "react";
import {
  useAppSelector,
  useAppDispatch,
  closeModal,
  selectModalOpen,
  selectModalSlug
} from "@src/lib/store";
import { Dialog } from "@headlessui/react";
import Close from "@/img/icons/close.svg";
import {
  ContentfulImage,
  MarketoForm,
  ProductComparisonTableBlock,
  ProductContainer,
  RichText
} from "@src/components";
import { Document } from "@contentful/rich-text-types";
import {
  ModalsCollectionItem,
  ModalsCollectionItemComparisonTable,
  ModalsCollectionItemContentForm
} from "@src/lib/services/server/contentful";
import { ModalsQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";

export type ModalProps = {
  modals?: ModalsQuery;
};

export type ModalStateProps = {
  isLoaded: boolean;
  setIsLoaded: (isLoaded: boolean) => void;
};

export type ModalContentProps = ModalStateProps & {
  modal?: ModalsCollectionItem | null;
};

export type ModalContentFormProps = ModalStateProps &
  ModalsCollectionItemContentForm;

export type ModelContentComparisonTableProps = ModalStateProps &
  ModalsCollectionItemComparisonTable;

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

function ModalContentForm({
  marketoFormId,
  formTheme,
  formHeading,
  subheading,
  finePrint,
  image,
  isLoaded,
  setIsLoaded
}: ModalContentFormProps) {
  React.useEffect(() => {
    return () => {
      if (window.MktoForms2 && window.MktoForms2._formIds) {
        window.MktoForms2._formIds = window.MktoForms2._formIds.filter(
          (id: string) => id !== marketoFormId
        );
      }
    };
  }, [marketoFormId]);
  return (
    <div className="flex flex-col justify-between gap-4 p-8 md:flex-row md:gap-12 md:px-16 md:py-[74px]">
      <div>
        {image && (
          <figure className="relative mx-auto h-[150px] w-[100px]">
            <ContentfulImage
              image={image}
              layout="fill"
              className="object-contain"
            />
          </figure>
        )}
      </div>
      <div>
        {formHeading && <div className="pb-2 text-pr-5xl">{formHeading}</div>}
        {subheading && (
          <div className="max-w-[330px] pb-2 text-pr-base leading-[1.68]">
            {subheading}
          </div>
        )}
        <MarketoForm
          formId={marketoFormId}
          formTheme={formTheme?.toLowerCase().split(" ").join("")}
          setIsLoaded={setIsLoaded}
        />
        {finePrint?.json && (
          <div className="fine-print mb-0 mt-2 text-left text-xs [&_a]:text-pr-teal-500">
            <RichText text={finePrint.json as Document} />
          </div>
        )}
      </div>
    </div>
  );
}

function ModalComparisonTable({
  heading,
  orientation,
  title,
  text,
  isLoaded,
  setIsLoaded
}: ModelContentComparisonTableProps) {
  setIsLoaded(true);

  const tableHeading = heading;
  const tableText = text?.json as Document | undefined;
  const tableOrientation = orientation
    ? orientation.toLowerCase().split(" ").join("")
    : null;
  const dispatch = useAppDispatch();
  const close = React.useCallback(() => {
    setIsLoaded(false);
    dispatch(closeModal());
  }, [dispatch]);
  return (
    <div className="my-10 w-full md:my-16 lg:my-24">
      <ProductContainer>
        <div className="relative">
          {tableHeading && (
            <h2 className="rounded-t-3xl bg-pr-dark-gray-500 py-5 text-center text-xl text-white">
              {returnLineBreaks(tableHeading)}
            </h2>
          )}
          <button
            onClick={close}
            className={`absolute right-6 top-5 block size-6`}
          >
            <Close className="stroke-round size-full stroke-white stroke-2" />
          </button>
        </div>
        {tableText && (
          <div
            className={`comparison-table block overflow-x-auto rounded-b-3xl ${
              tableOrientation == "center" ? "comparison-table-centered" : ""
            }`}
          >
            <RichText text={tableText} />
          </div>
        )}
      </ProductContainer>
    </div>
  );
}

function ModalContent({ modal, ...props }: ModalContentProps) {
  switch (modal?.content?.__typename) {
    case "ModalForms":
      return <ModalContentForm {...modal.content} {...props} />;
    case "ModalComparisonTable":
      return <ModalComparisonTable {...modal.content} {...props} />;
  }
  return null;
}

export function Modal({ modals }: ModalProps) {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const modalOpen = useAppSelector(selectModalOpen);
  const modalSlug = useAppSelector(selectModalSlug);
  const modal = modals?.modalsCollection?.items.find(
    modal => modal && modal.slug === modalSlug
  );
  const dispatch = useAppDispatch();
  const close = React.useCallback(() => {
    setIsLoaded(false);
    dispatch(closeModal());
  }, [dispatch]);
  return (
    <Dialog open={modalOpen} onClose={close} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div
        className={`fixed inset-0 flex p-8 ${
          modal?.content?.__typename == "ModalForms"
            ? "max-h-screen items-center justify-center"
            : "overflow-y-scroll lg:justify-center"
        }`}
      >
        <Dialog.Panel
          className={`mx-auto max-h-full ${
            modal?.content?.__typename == "ModalForms" ? "overflow-x-auto" : ""
          } `}
        >
          {!isLoaded && (
            <div className="calendly-spinner">
              <div className="calendly-bounce1"></div>
              <div className="calendly-bounce2"></div>
              <div className="calendly-bounce3"></div>
            </div>
          )}
          <div
            className={`mx-auto overflow-x-auto rounded-3xl ${
              isLoaded ? " opacity-100 " : " opacity-0 "
            } ${
              modal?.content?.__typename == "ModalForms"
                ? "max-h-full bg-white "
                : "relative"
            } `}
          >
            <ModalContent
              modal={modal}
              isLoaded={isLoaded}
              setIsLoaded={setIsLoaded}
            />
          </div>
          <button
            onClick={close}
            className={`absolute right-4 top-4 block size-6 md:right-8 md:top-8 ${
              modal?.content?.__typename == "ModalForms" ? "" : "hidden"
            }`}
          >
            <Close className="stroke-round size-full stroke-white stroke-2" />
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default Modal;
