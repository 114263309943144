import * as React from "react";
import { AccordionGroup } from "@src/lib/services/server/contentful";
import { Disclosure, Transition } from "@headlessui/react";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText } from "@src/components";
import Plus from "@/img/icons/plus.svg";
import Minus from "@/img/icons/minus.svg";

export type ProductAccordionBlockProps = {
  heading?: string | null;
  items: AccordionGroup[];
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductAccordionBlock({
  heading,
  items
}: ProductAccordionBlockProps) {
  const renderItems = (items: AccordionGroup[]) => {
    return items.map((item, index) => {
      const text = item.text?.json as Document | undefined;
      return (
        <div key={index} className="border-b">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center gap-4 py-6 text-left text-pr-red-600 md:py-5">
                  <Plus className={open ? "hidden" : "size-[13px]"} />
                  <Minus className={open ? "size-[13px]" : "hidden"} />

                  {item.heading && (
                    <span className="type-epsilon">
                      {returnLineBreaks(item.heading)}
                    </span>
                  )}
                </Disclosure.Button>
                <Transition
                  className="overflow-hidden"
                  enter="transition transition-[max-height] duration-200 ease-in"
                  enterFrom="transform max-h-0"
                  enterTo="transform max-h-screen"
                  leave="transition transition-[max-height] duration-200 ease-out"
                  leaveFrom="transform max-h-screen"
                  leaveTo="transform max-h-0"
                >
                  <Disclosure.Panel static={true} className="pb-7 md:pb-9">
                    {text && (
                      <div className="prose prose-pr lg:prose-pr-lg">
                        <RichText text={text} />
                      </div>
                    )}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      );
    });
  };

  return (
    <div>
      <ProductContainer>
        <div className="mx-auto max-w-[800px]">
          <div className="w-full space-y-4">
            {heading && (
              <h2 className="type-pr-delta md:type-pr-epsilon border-t pt-5 font-bold md:pt-6">
                {returnLineBreaks(heading)}
              </h2>
            )}
            <div>{renderItems(items)}</div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductAccordionBlock;
