import * as React from "react";
import { Service } from "@src/lib/services/server/contentful";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms,
  isExternalLink
} from "@src/lib/utils";
import {
  ProductContainer,
  RichText,
  SmartLink,
  ContentfulImage
} from "@src/components";
import External from "@/img/icons/external.svg";

export type ProductTestimonialsBlockProps = {
  text?: Document;
  image?: IContentfulImage | null;
  sourceLogo?: IContentfulImage | null;
  sourceName?: string | null;
  sourceTitle?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  servicesLabel?: string | null;
  items: Service[];
};

export function ProductTestimonialsBlock({
  text,
  image,
  sourceLogo,
  sourceName,
  sourceTitle,
  ctaTitle,
  ctaUrl,
  servicesLabel,
  items
}: ProductTestimonialsBlockProps) {
  const imageDimensions = {
    width: 3320,
    height: 1634
  };

  const imageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: imageDimensions.width,
    height: imageDimensions.height
  };

  // const sourceLogoDimensions = {
  //   width: 280,
  //   height: 144,
  // };

  // const sourceLogoTransforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: sourceLogoDimensions.width,
  //   height: sourceLogoDimensions.height,
  // };

  const renderItems = (items: Service[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          href={contentfulEntryUrl(item)}
          className="pr-button pr-button-sm pr-button-light-gray"
          key={index}
        >
          {item.previewTitle || item.title}
        </SmartLink>
      );
    });
  };

  return (
    <div className="relative bg-pr-dark-gray-500 pb-10 pt-12 md:py-44">
      <ProductContainer>
        <figure className="absolute inset-0 size-full opacity-40">
          <ContentfulImage
            image={image}
            transforms={imageTransforms}
            layout="fill"
            className="object-cover"
          />
        </figure>
        <div className="relative mx-auto max-w-[940px] text-white">
          <div className="grid gap-5 md:grid-cols-2 md:gap-y-24">
            {text && (
              <div className="col-span-full">
                <div className="quote-pr type-pr-delta">
                  <RichText text={text} />
                </div>
              </div>
            )}

            <div>
              <div className="flex items-center">
                {sourceLogo && (
                  <div
                    className={`py-1.5 lg:py-3 ${
                      sourceName || sourceTitle
                        ? "border-r border-white pr-5 lg:pr-8"
                        : ""
                    }`}
                  >
                    <figure className="relative aspect-[3/2] w-[104px] lg:w-[140px]">
                      <ContentfulImage
                        image={sourceLogo}
                        layout="fill"
                        className="object-contain"
                      />
                    </figure>
                  </div>
                )}

                {sourceName && (
                  <div
                    className={`type-pr-zeta py-1.5 lg:py-3 ${
                      sourceLogo ? "pl-5 lg:pl-8" : ""
                    }`}
                  >
                    <p>{sourceName}</p>
                    {sourceTitle && <p>{sourceTitle}</p>}
                  </div>
                )}
              </div>

              {items.length > 0 && (
                <div
                  className={`space-y-2.5 lg:space-y-4 ${
                    sourceLogo || sourceName ? "mt-4 md:mt-20" : ""
                  }`}
                >
                  {servicesLabel && (
                    <p className="text-center text-pr-xs uppercase md:text-left lg:text-pr-base">
                      {servicesLabel}
                    </p>
                  )}
                  <div className="flex flex-wrap justify-center gap-2.5 md:justify-start">
                    {renderItems(items)}
                  </div>
                </div>
              )}
            </div>

            {ctaUrl && ctaTitle && (
              <div>
                <div className="flex justify-center lg:py-5">
                  <SmartLink
                    href={ctaUrl}
                    className={"pr-button pr-button-lg pr-button-dark-gray"}
                  >
                    {ctaTitle}
                    {isExternalLink(ctaUrl) && (
                      <External className="size-5 fill-current" />
                    )}
                  </SmartLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductTestimonialsBlock;
