import * as React from "react";

export type ProductContainerProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
};

export function ProductContainer({
  children,
  fullWidth = false
}: ProductContainerProps) {
  return (
    <div
      className={`mx-auto w-full px-5 lg:px-8 2xl:px-24 ${
        fullWidth ? "" : "relative max-w-screen-4xl"
      }`}
    >
      {children}
    </div>
  );
}

export default ProductContainer;
