import { IContentfulImage } from "@src/lib/types";
import * as React from "react";
import { ContentfulImage, ProductContainer, SmartLink } from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { Disclosure, Transition } from "@headlessui/react";

export type ProductProductWebinarBlockProps = {
  heading?: string | null;
  numberOfVisibleCards?: number | null | undefined;
  items?: ProductWebinarBlockCardProps[];
  length?: number | null;
  index?: number | null;
};

export type ProductWebinarBlockCardProps = {
  heading?: string | null;
  url?: string | null;
  image?: IContentfulImage | null;
  eyebrow?: string | null;
  text?: string | null;
  headingColor?: string | null;
  open?: boolean;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

const dimensions = {
  width: Math.round(1486 / 1.5),
  height: Math.round(892 / 1.5)
};

const transforms: ContentfulImageUrlTransforms = {
  fit: "fill" as const,
  focus: "center" as const,
  format: "webp" as const,
  quality: 50,
  width: dimensions.width,
  height: dimensions.height
};

function ProductWebinarBlockCard({
  heading,
  headingColor,
  url,
  image,
  text,
  eyebrow,
  open
}: ProductWebinarBlockCardProps) {
  const defaultheadingColor: string = "teal";
  headingColor = headingColor?.toLowerCase().split(" ").join("");

  const headingClassNames: { [key: string]: string } = {
    red: "text-pr-red-500",
    green: "text-pr-green-500",
    yellow: "text-pr-yellow-500",
    teal: "text-pr-teal-500",
    black: "text-pr-gray-500"
  };

  const headingClassName =
    headingClassNames[headingColor ? headingColor : defaultheadingColor];
  return (
    <div className={`flex md:max-w-[349px] ${open ? "block" : "hidden"} `}>
      {url && (
        <SmartLink
          href={url}
          className="group mx-auto flex size-full flex-col overflow-hidden rounded-2xl bg-pr-yellow-200 transition hover:drop-shadow-md focus:drop-shadow-md md:max-w-[440px] md:rounded-3xl"
        >
          {image && (
            <figure className="relative aspect-[2120/1376]">
              <ContentfulImage
                image={image}
                transforms={transforms}
                layout="fill"
                className="object-cover"
              />
            </figure>
          )}
          <div
            className={`grow p-[30px] px-6 pb-8 pt-5 xl:p-[33px] ${
              image ? "space-y-2 md:space-y-3" : "space-y-4 md:space-y-8"
            }`}
          >
            {eyebrow && (
              <div className="type-pr-theta">{returnLineBreaks(eyebrow)}</div>
            )}
            {heading && (
              <h3
                className={`type-pr-eta link-underline-animated-sm lg:text-[22px] lg:font-normal lg:leading-8 xl:text-[30px] ${headingClassName}`}
              >
                {returnLineBreaks(heading)}
              </h3>
            )}
            {text && (
              <p className="text-lg leading-6 text-pr-dark-gray-500 xl:text-xl">
                {text}
              </p>
            )}
          </div>
        </SmartLink>
      )}
    </div>
  );
}

export function ProductWebinarBlock({
  heading,
  numberOfVisibleCards,
  items,
  length,
  index
}: ProductProductWebinarBlockProps) {
  const isLastModule = index ? length == index + 1 : false;
  const borderClass = isLastModule
    ? " border-none "
    : " border-b  border-[#30323B] ";
  if (items && numberOfVisibleCards && items.length > numberOfVisibleCards) {
    const visibleItems = items.slice(0, numberOfVisibleCards);
    const hiddenItems = items.slice(numberOfVisibleCards, items.length);
    return (
      <div className="pt-10 lg:pt-[50px]">
        <ProductContainer>
          <div className="px-1 md:px-16 lg:px-32">
            <div className={`pb-10 lg:pb-[50px] ${borderClass}`}>
              <div className="flex flex-col gap-5 md:gap-0">
                {heading && (
                  <h2 className="pb-5 text-pr-6xl font-semibold leading-pr-relaxed tracking-pr-wider lg:text-[2.75rem]">
                    {" "}
                    {returnLineBreaks(heading)}{" "}
                  </h2>
                )}
                <div className="grid w-full gap-10 md:grid-cols-2 lg:grid-cols-3 lg:gap-[50px]">
                  {visibleItems.map((item, index) => (
                    <ProductWebinarBlockCard
                      key={index}
                      {...item}
                      open={true}
                    />
                  ))}
                  <Disclosure>
                    {({ open }) => (
                      <>
                        {hiddenItems.map((item, index) => (
                          <ProductWebinarBlockCard
                            key={index}
                            {...item}
                            open={open}
                          />
                        ))}
                        <Disclosure.Button
                          className={`mx-auto md:col-span-2 lg:col-span-3`}
                        >
                          <div
                            className={` ${
                              open
                                ? "pr-button pr-button-lg bg-[#E1E0E0] text-white  "
                                : " pr-button pr-button-lg pr-button-teal "
                            } `}
                          >
                            Show more
                          </div>
                        </Disclosure.Button>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
          </div>
        </ProductContainer>
      </div>
    );
  } else {
    return (
      <div className="pt-12 lg:pt-[50px]">
        <ProductContainer>
          <div className="px-1 md:px-16 lg:px-32">
            <div className={`pb-12 lg:pb-[50px] ${borderClass}`}>
              {heading && (
                <h2 className="pb-5 text-pr-6xl font-semibold leading-pr-relaxed tracking-pr-wider lg:text-[2.75rem]">
                  {" "}
                  {returnLineBreaks(heading)}{" "}
                </h2>
              )}
              <div className="flex flex-col gap-5 md:gap-0">
                {items && (
                  <div className="grid w-full gap-[50px] md:grid-cols-2 lg:grid-cols-3">
                    {items.map((item, index) => (
                      <ProductWebinarBlockCard
                        key={index}
                        {...item}
                        open={true}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ProductContainer>
      </div>
    );
  }
}

export default ProductWebinarBlock;
