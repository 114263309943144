import * as React from "react";
import { ProductHeroSlideContent } from "../ProductHeroSlideContent";
import { ProductHeroSlideImage } from "../ProductHeroSlideImage";

export type ProductHeroSlideProps = {
  index: number;
  slideTitle?: string | null;
  slideDescription?: string | null;
  slideButtonTitle?: string | null;
  slideButtonLink?: string | null;
  slideImage?: {
    description?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
};

const ProductHeroSlide: React.FC<ProductHeroSlideProps> = ({
  index,
  slideTitle,
  slideDescription,
  slideButtonTitle,
  slideButtonLink,
  slideImage
}) => {
  return (
    <>
      <ProductHeroSlideContent
        slideTitle={slideTitle}
        slideDescription={slideDescription}
        slideButtonTitle={slideButtonTitle}
        slideButtonLink={slideButtonLink}
      />
      <ProductHeroSlideImage index={index} slideImage={slideImage} />
    </>
  );
};

export { ProductHeroSlide };
