import * as React from "react";

export type ProductHeroSlideImageProps = {
  index: number;
  slideImage?: {
    description?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
};

const ProductHeroSlideImage: React.FC<ProductHeroSlideImageProps> = ({
  index,
  slideImage
}) => {
  const backgroundClasses: { [key: number]: string } = {
    0: "bg-ada-blue-400/10",
    1: "bg-ada-green-400/10",
    2: "bg-ada-salmon-300/10",
    3: "bg-ada-blue-400/10",
    4: "bg-ada-green-400/10"
  };

  return (
    <div
      id="hero-slide__image-column"
      className={`${backgroundClasses[index]} flex flex-1 basis-2/3 flex-col justify-center self-stretch rounded-br-3xl rounded-tl-3xl p-8 pr-16 md:pr-20`}
    >
      <figure className="flex h-[125px] justify-center xs:h-[200px] sm:h-[264px] md:h-auto">
        <img
          src={slideImage?.url ?? ""}
          alt={slideImage?.description ?? ""}
          className="object-contain"
        ></img>
      </figure>
    </div>
  );
};

export { ProductHeroSlideImage };
