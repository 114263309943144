import * as React from "react";
import { ProductContainer } from "@src/components";

export type ProductHtmlBlockProps = {
  html?: string | null;
};

export function ProductHtmlBlock({ html }: ProductHtmlBlockProps) {
  if (!html) {
    return null;
  }
  return (
    <div>
      <ProductContainer>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </ProductContainer>
    </div>
  );
}

export default ProductHtmlBlock;
