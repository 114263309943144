import * as React from "react";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { ProductContainer, ContentfulImage, SmartLink } from "@src/components";
import { IContentfulImage } from "@src/lib/types";
import Chevron from "@/img/icons/chevron.svg";

export type ProductHeroSolutionProps = {
  eyebrow?: string | null;
  heading?: string | null;
  text?: string | null;
  image?: IContentfulImage | null;
  icon?: IContentfulImage | null;
  heroStyle?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductHeroSolution({
  eyebrow,
  heading,
  text,
  image,
  icon,
  heroStyle,
  ctaTitle,
  ctaUrl,
  ctaStyle
}: ProductHeroSolutionProps) {
  const imageDimensions = {
    width: 1112,
    height: 1112
  };

  const imageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: imageDimensions.width,
    height: imageDimensions.height
  };

  // const iconDimensions = {
  //   width: 140,
  //   height: 140,
  // };

  // const iconTransforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: iconDimensions.width,
  //   height: iconDimensions.height,
  // };

  const defaultCtaStyle: string = "red";

  const ctaClassNames: { [key: string]: string } = {
    red: "pr-button-red",
    green: "bg-pr-green-500 text-white",
    yellow: "bg-pr-yellow-500 text-white",
    teal: "bg-pr-teal-500 text-white",
    black: "pr-button-black"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <div>
      {heroStyle != "hidden" && (
        <div className="overflow-hidden bg-pr-yellow-200 pt-6 md:pt-0">
          <ProductContainer fullWidth={true}>
            <div className="grid gap-6 md:grid-cols-2 md:gap-0">
              <div className="flex w-full flex-col justify-center md:max-w-[704px] md:justify-self-end md:pb-16 md:pt-8">
                <div className="relative z-[1] space-y-8">
                  <div className="space-y-2 pr-[18%] lg:space-y-6">
                    {eyebrow && (
                      <p className="type-pr-eta md:type-pr-theta text-pr-dark-gray-400">
                        {eyebrow}
                      </p>
                    )}
                    <div
                      className={`space-y-2.5 ${
                        icon ? "lg:space-y-4" : "lg:space-y-11"
                      }`}
                    >
                      {heading && (
                        <div
                          className={`${
                            icon ? "flex items-start gap-2.5 lg:gap-7" : ""
                          }`}
                        >
                          {icon && (
                            <figure className="relative mt-1 size-11 shrink-0 lg:size-[70px]">
                              <ContentfulImage
                                image={icon}
                                layout="fill"
                                className="object-contain"
                                priority={true}
                              />
                            </figure>
                          )}
                          <h1
                            className={`${
                              icon
                                ? "type-pr-alpha lg:type-pr-beta"
                                : "type-pr-alpha"
                            }`}
                          >
                            {returnLineBreaks(heading)}
                          </h1>
                        </div>
                      )}

                      {text && (
                        <div
                          className={`type-pr-zeta ${
                            icon
                              ? "md:type-pr-eta text-pr-dark-gray-400"
                              : "lg:text-pr-4xl"
                          } `}
                        >
                          <p>{returnLineBreaks(text)}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {ctaUrl && ctaTitle && (
                    <SmartLink
                      href={ctaUrl}
                      className={`pr-button pr-button-lg ${ctaClassName} `}
                    >
                      {ctaTitle}
                    </SmartLink>
                  )}
                </div>
              </div>
              <div className="-mr-5 flex flex-col justify-end md:ml-[clamp(-20%,2.5vw,-18.75rem)] lg:-mr-8 lg:ml-[clamp(-45%,2.5vw,-18.75rem)] 2xl:-mr-24">
                <div className="relative px-[25%]">
                  {/* @TODO: image shape with svg clipping mask would be nice, also tweak svg position so its flush to right */}
                  {heroStyle == "motif" && (
                    <svg
                      viewBox="0 0 370 187"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute inset-x-0 bottom-0 size-full"
                    >
                      <path
                        d="M92.5833 94.0723H46.4877V140.504H92.5833V94.0723Z"
                        className="fill-[#FFE7B8]"
                      />
                      <path
                        d="M323.846 47.1348H277.414V139.662H323.846V47.1348Z"
                        className="fill-[#F37059]"
                      />
                      <path
                        d="M369.942 139.662H323.51V186.094H369.942V139.662Z"
                        className="fill-[#FFE7B8]"
                      />
                      <path
                        d="M1.14441e-05 139.998L46.432 139.998L46.432 186.094C20.7873 186.094 1.03312e-05 165.457 1.14441e-05 139.998Z"
                        className="fill-[#F37059]"
                      />
                      <path
                        d="M323.51 47.1351H277.414V0.703125C302.873 0.703125 323.51 21.4905 323.51 47.1351Z"
                        className="fill-[#FFE7B8]"
                      />
                      <path
                        d="M370 1.0957H323.513V47.5829H370V1.0957Z"
                        className="fill-pr-dark-gray-700"
                      />
                    </svg>
                  )}
                  <figure className="relative aspect-square">
                    <ContentfulImage
                      image={image}
                      transforms={imageTransforms}
                      layout="fill"
                      className="rounded-br-[54px] rounded-tl-[54px] object-cover 2xl:rounded-br-[150px] 2xl:rounded-tl-[150px]"
                      priority={true}
                    />
                  </figure>
                </div>
              </div>
            </div>
          </ProductContainer>
        </div>
      )}
    </div>
  );
}

export default ProductHeroSolution;
