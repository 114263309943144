import * as React from "react";
import { SmartLink } from "../SmartLink";

export type ProductHeroSlideContentProps = {
  slideTitle?: string | null;
  slideDescription?: string | null;
  slideButtonTitle?: string | null;
  slideButtonLink?: string | null;
};

const ProductHeroSlideContent: React.FC<ProductHeroSlideContentProps> = ({
  slideTitle,
  slideDescription,
  slideButtonTitle,
  slideButtonLink
}) => {
  return (
    <div
      id="hero-slide__content-column"
      className="flex flex-1 basis-1/3 flex-col justify-center self-stretch rounded-br-3xl rounded-tl-3xl bg-pr-yellow-200 p-8"
    >
      <div
        id="hero-slide__content-column--body"
        className="flex flex-col items-start gap-3"
      >
        <h1 className="text-4xl font-bold text-ada-salmon-500 md:text-5xl">
          {slideTitle}
        </h1>
        <p>{slideDescription}</p>
        <SmartLink
          href={slideButtonLink ?? ""}
          className={`
            pr-button
            pr-button-lg
            pr-button
            ease
            bg-ada-teal-400
            text-white
            hover:bg-ada-salmon-500
            lg:mx-0
            `}
        >
          {slideButtonTitle}
        </SmartLink>
      </div>
    </div>
  );
};

export { ProductHeroSlideContent };
