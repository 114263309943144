import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { ProductContainer, ContentfulImage } from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type TrendReportTwoColumnImageWithTextProps = {
  textPosition?: string | null;
  items?: TrendReportImageWithTextProps[];
  hasBackground?: boolean | null;
};

export type TrendReportImageWithTextProps = {
  eyebrow?: string | null;
  heading?: string | null;
  description?: string | null;
  image?: IContentfulImage | null;
  hasBackground?: boolean | null;
};

function TextCol({
  eyebrow,
  heading,
  description
}: TrendReportImageWithTextProps) {
  if (eyebrow || heading || description) {
    return (
      <div className="space-y-3.5 pb-16 lg:basis-1/2 lg:space-y-8 lg:pb-24">
        {eyebrow && (
          <p className={`type-pr-theta lg:type-pr-eta text-pr-dark-gray-400`}>
            {eyebrow}
          </p>
        )}
        {heading && <h3 className="type-pr-delta font-bold">{heading}</h3>}
        {description && (
          <div className="text-pr-base leading-pr-looser text-pr-dark-gray-500 lg:text-pr-xl">
            {description}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
}

function ImageCol({ image }: TrendReportImageWithTextProps) {
  const imageDimensions = {
    width: image?.width || 463,
    height: image?.height || 463
  };

  const transforms: ContentfulImageUrlTransforms = {
    width: imageDimensions.width,
    height: imageDimensions.height
  };

  return (
    <div>
      {image && (
        <figure className="relative col-span-1">
          <ContentfulImage image={image} transforms={transforms} />
        </figure>
      )}
    </div>
  );
}

function ImageWithTextOverlap({
  eyebrow,
  heading,
  image,
  description,
  hasBackground
}: TrendReportImageWithTextProps) {
  const dimensions = {
    width: 450,
    height: 450
  };

  const backgroundClass = hasBackground
    ? "px-6 py-8 md:px-9 md:py-10 lg:px-8 lg:pt-16 lg:pb-20 xl:px-10 xl:pt-24 xl:pb-28"
    : "px-9 py-10 lg:px-10 lg:pt-16 lg:pb-20 xl:px-16 xl:pt-24 xl:pb-28";

  return (
    <div className={`relative size-full ${backgroundClass}`}>
      <div className={`z-10 flex flex-col space-y-2 lg:space-y-6 xl:space-y-8`}>
        {eyebrow && (
          <p className={`type-pr-theta lg:type-pr-eta text-pr-dark-gray-400`}>
            {eyebrow}
          </p>
        )}
        {heading && <h3 className="type-pr-delta font-bold">{heading}</h3>}
        {description && (
          <div className="text-pr-base leading-pr-looser text-pr-dark-gray-500 lg:text-pr-xl">
            {description}
          </div>
        )}
      </div>
      {image && (
        <figure className="absolute inset-0 z-[-5] size-full">
          <ContentfulImage
            image={image}
            layout="fill"
            className="object-cover"
          />
        </figure>
      )}
    </div>
  );
}

export function TrendReportTwoColumnImageWithText({
  textPosition,
  items,
  hasBackground
}: TrendReportTwoColumnImageWithTextProps) {
  if (textPosition == "Above") {
    return (
      <ProductContainer>
        {items && (
          <div className="">
            <div
              className={`flex flex-col gap-16 lg:flex-row lg:justify-between lg:gap-14`}
            >
              {items.map((item, index) => (
                <TextCol key={index} {...item} />
              ))}
            </div>
            <div
              className={`grid gap-3 lg:grid-cols-2 lg:flex-row lg:justify-between lg:gap-14`}
            >
              {items.map((item, index) => (
                <ImageCol key={index} image={item.image} />
              ))}
            </div>
          </div>
        )}
      </ProductContainer>
    );
  } else {
    return (
      <ProductContainer>
        {items && (
          <div className={`flex flex-col gap-14 lg:flex-row`}>
            {items.map((item, index) => (
              <ImageWithTextOverlap
                key={index}
                {...item}
                hasBackground={hasBackground}
              />
            ))}
          </div>
        )}
      </ProductContainer>
    );
  }
}

export default TrendReportTwoColumnImageWithText;
