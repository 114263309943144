import * as React from "react";
import { SolutionFeature } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";
import { Pagination, A11y, Navigation } from "swiper";
import {
  ProductContainer,
  RichText,
  ContentfulImage,
  SmartLink
} from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import Arrow from "@/img/icons/arrow.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export type ProductSolutionFeaturesBlockProps = {
  id?: string | null;
  heading?: string | null;
  text?: Document;
  solutionsStyle?: string | null;
  items: SolutionFeature[];
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductSolutionFeaturesBlock({
  id = "",
  heading,
  text,
  solutionsStyle,
  items
}: ProductSolutionFeaturesBlockProps) {
  const defaultSolutionsStyle: string = "red";

  const headingClassNames: { [key: string]: string } = {
    black: "type-[21px] xl:text-pr-3xl text-pr-dark-gray-500 font-bold",
    red: "type-pr-delta text-pr-red-600 xl:text-pr-7xl"
  };

  const solutionsStylesClassNames: { [key: string]: string } = {
    black: "type-[21px] xl:text-pr-3xl text-pr-dark-gray-500 font-bold",
    red: "type-pr-delta text-pr-red-600 xl:text-pr-7xl"
  };

  const spacingClassNames: { [key: string]: string } = {
    black: "space-y-1",
    red: "space-y-1.5 md:space-y-4"
  };

  const textClassNames: { [key: string]: string } = {
    black: "type-[21px] lg:text-[24px] text-pr-dark-gray-500",
    red: "text-pr-dark-gray-400 lg:text-pr-lg"
  };

  const headingClassName =
    headingClassNames[solutionsStyle ? solutionsStyle : defaultSolutionsStyle];
  const solutionsStylesClassName =
    solutionsStylesClassNames[
      solutionsStyle ? solutionsStyle : defaultSolutionsStyle
    ];
  const spacingClassName =
    spacingClassNames[solutionsStyle ? solutionsStyle : defaultSolutionsStyle];
  const textClassName =
    textClassNames[solutionsStyle ? solutionsStyle : defaultSolutionsStyle];

  const dimensions = {
    width: 872,
    height: 586
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  const prevClassName: string = `prev-${id as string}`;
  const nextClassName: string = `next-${id as string}`;

  const renderItems = (items: SolutionFeature[]) => {
    return items.map((item, index) => {
      const description = item.description?.json as Document | undefined;
      if (item.referenceUrl) {
        return (
          <div key={index} className="group">
            <SmartLink
              href={item.referenceUrl || ""}
              className="space-y-5 md:space-y-8"
            >
              {item.leadImage && (
                <div>
                  {solutionsStyle === "black" ? (
                    <figure className="flex justify-center">
                      <img
                        src={item.leadImage.url || ""}
                        alt={item.leadImage.description || ""}
                      />
                    </figure>
                  ) : (
                    <figure>
                      <ContentfulImage
                        image={item.leadImage}
                        transforms={transforms}
                        className="rounded-3xl"
                      />
                    </figure>
                  )}
                </div>
              )}
              <div className={`${spacingClassName} text-center`}>
                {item.heading && (
                  <h3 className={`${solutionsStylesClassName}`}>
                    {item.heading}
                  </h3>
                )}
                {description && (
                  <div className={`${textClassName} mx-14 md:mx-6`}>
                    <RichText text={description} />
                  </div>
                )}
              </div>
            </SmartLink>
          </div>
        );
      } else {
        return (
          <div key={index} className="space-y-5 md:space-y-8">
            {item.leadImage && (
              <div>
                {solutionsStyle === "black" ? (
                  <figure className="flex justify-center">
                    <img
                      src={item.leadImage.url || ""}
                      alt={item.leadImage.description || ""}
                    />
                  </figure>
                ) : (
                  <figure>
                    <ContentfulImage
                      image={item.leadImage}
                      transforms={transforms}
                      className="rounded-3xl"
                    />
                  </figure>
                )}
              </div>
            )}
            <div className={`${spacingClassName} text-center`}>
              {item.heading && (
                <h3 className={`${solutionsStylesClassName}`}>
                  {item.heading}
                </h3>
              )}
              {description && (
                <div className={`${textClassName} mx-14 md:mx-6`}>
                  <RichText text={description} />
                </div>
              )}
            </div>
          </div>
        );
      }
    });
  };

  const renderSlides = (items: SolutionFeature[]) => {
    return items.map((item, index) => {
      const description = item.description?.json as Document | undefined;
      if (item.referenceUrl) {
        return (
          <SwiperSlide key={index}>
            <div className="group">
              <SmartLink
                href={item.referenceUrl || ""}
                className="space-y-5 md:space-y-8"
              >
                {item.leadImage && (
                  <figure className="flex justify-center">
                    <img
                      src={item.leadImage.url || ""}
                      alt={item.leadImage.description || ""}
                    />
                  </figure>
                )}
                <div className={`${spacingClassName} text-center`}>
                  {item.heading && (
                    <h3 className={`${solutionsStylesClassName}`}>
                      {item.heading}
                    </h3>
                  )}
                  {description && (
                    <div className={`${textClassName} mx-14 md:mx-6`}>
                      <RichText text={description} />
                    </div>
                  )}
                </div>
              </SmartLink>
            </div>
          </SwiperSlide>
        );
      } else {
        return (
          <SwiperSlide key={index}>
            <div className="space-y-5 md:space-y-8">
              {item.leadImage && (
                <figure className="flex justify-center">
                  <img
                    src={item.leadImage.url || ""}
                    alt={item.leadImage.description || ""}
                  />
                </figure>
              )}
              <div className={`${spacingClassName} text-center`}>
                {item.heading && (
                  <h3 className={`${solutionsStylesClassName}`}>
                    {item.heading}
                  </h3>
                )}
                {description && (
                  <div className={`${textClassName} mx-14 md:mx-6`}>
                    <RichText text={description} />
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        );
      }
    });
  };

  return (
    <div>
      <ProductContainer>
        <div
          className={`relative ${
            heading || text ? "space-y-[35px] lg:space-y-12" : ""
          }`}
        >
          <div className="mx-auto max-w-[888px] space-y-4 text-center">
            {heading && (
              <h2 className="text-[30px] font-bold">
                {returnLineBreaks(heading)}
              </h2>
            )}
            {text && (
              <div className="type-pr-zeta text-pr-dark-gray-400">
                <RichText text={text} />
              </div>
            )}
          </div>

          <div className="hidden gap-16 md:grid-cols-3 md:gap-x-7 md:gap-y-14 lg:grid lg:gap-x-12 lg:gap-y-20">
            {renderItems(items)}
          </div>

          {/* Mobile Items */}
          <div className="lg:hidden">
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              spaceBetween={16}
              slidesPerView={"auto"}
              navigation={{
                prevEl: `.${prevClassName}`,
                nextEl: `.${nextClassName}`
              }}
              centerInsufficientSlides={true}
              className="swiper-solutions"
            >
              {renderSlides(items)}
            </Swiper>
            <div>
              <button
                className={`absolute bottom-1/2 left-0 z-[2] ${prevClassName}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="23"
                  viewBox="0 0 19 23"
                  fill="none"
                >
                  <path
                    d="M0 11.5L18.75 0.674683V22.3253L0 11.5Z"
                    fill="#D9D9D9"
                  />
                </svg>
              </button>
              <button
                className={`absolute bottom-1/2 right-0 z-[2] disabled:opacity-50 ${nextClassName}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="23"
                  viewBox="0 0 19 23"
                  fill="none"
                >
                  <path
                    d="M19 11.5L0.25 22.3253V0.674683L19 11.5Z"
                    fill="#D9D9D9"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductSolutionFeaturesBlock;
