import * as React from "react";

const ProductHeroSlideMotionControls = () => {
  return (
    <div id="hero-slider-buttons" className="swiper-buttons">
      <button
        className="swiper-button-next rounded-full bg-ada-yellow-500 p-3 ease-in hover:bg-ada-green-400 focus:bg-ada-green-400 sm:p-3.5"
        name="Next"
      ></button>
      <button
        className="swiper-button-prev rounded-full bg-ada-yellow-500 p-3 ease-in hover:bg-ada-green-400 focus:bg-ada-green-400 sm:p-3.5"
        name="Previous"
      ></button>
    </div>
  );
};

export { ProductHeroSlideMotionControls };
