/* eslint-disable tailwindcss/no-contradicting-classname */
import * as React from "react";
import { Service } from "@src/lib/services/server/contentful";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import {
  ProductContainer,
  RichText,
  SmartLink,
  ContentfulImage,
  ProductVideoBlock
} from "@src/components";
import Arrow from "@/img/icons/arrow.svg";
import { PlyrVideo } from "../PlyrVideo";

export type ProductImageWithTextBlockProps = {
  heading?: string | null;
  eyebrow?: string | null;
  text?: Document | null;
  image?: IContentfulImage | null;
  video?: string | null;
  primaryIcon?: IContentfulImage | null;
  secondaryIcon?: IContentfulImage | null;
  secondaryText?: Document | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  ctaStyle?: string | null;
  ctaId?: string | null;
  orientation?: string | null;
  servicesLabel?: string | null;
  items: Service[];
};

export function ProductImageWithTextBlock({
  eyebrow,
  heading,
  text,
  image,
  video,
  primaryIcon,
  secondaryIcon,
  secondaryText,
  ctaTitle,
  ctaUrl,
  ctaStyle = "primary",
  ctaId,
  orientation = "fullwidthwithimageonright",
  servicesLabel,
  items
}: ProductImageWithTextBlockProps) {
  const containedImageDimensions = {
    width: image?.width || 1648,
    height: image?.height || 1470
  };

  const containedImageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: containedImageDimensions.width,
    height: containedImageDimensions.height
  };

  const fullWidthImageDimensions = {
    width: 1648,
    height: 1470
  };

  const fullWidthImageTransforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: fullWidthImageDimensions.width,
    height: fullWidthImageDimensions.height
  };

  // const primaryIconDimensions = {
  //   width: 180,
  //   height: 180,
  // };

  // const primaryIconTransforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: primaryIconDimensions.width,
  //   height: primaryIconDimensions.height,
  // };

  // const secondaryIconDimensions = {
  //   width: 180,
  //   height: 180,
  // };

  // const secondaryIconTransforms: ContentfulImageUrlTransforms = {
  //   fit: "fill" as const,
  //   focus: "center" as const,
  //   format: "webp" as const,
  //   quality: 50,
  //   width: secondaryIconDimensions.width,
  //   height: secondaryIconDimensions.height,
  // };

  const defaultOrientation: string = "fullwidthwithimageonright";

  const headingClassNames: { [key: string]: string } = {
    containedwithimageonleft: "type-pr-beta",
    containedwithimageonright: "type-pr-beta",
    fullwidthwithimageonleft: "type-pr-delta",
    fullwidthwithimageonright: "type-pr-delta"
  };

  const figureClassNames: { [key: string]: string } = {
    containedwithimageonleft: "",
    containedwithimageonright: "",
    fullwidthwithimageonleft: "h-full aspect-square",
    fullwidthwithimageonright: "h-full aspect-square"
  };

  const imageClassNames: { [key: string]: string } = {
    containedwithimageonleft: "",
    containedwithimageonright: "",
    fullwidthwithimageonleft: "object-cover",
    fullwidthwithimageonright: "object-cover"
  };

  const imageLayouts: { [key: string]: "fill" | "responsive" } = {
    containedwithimageonleft: "responsive",
    containedwithimageonright: "responsive",
    fullwidthwithimageonleft: "fill",
    fullwidthwithimageonright: "fill"
  };

  const colClassNames: { [key: string]: string } = {
    containedwithimageonleft:
      "mx-auto max-w-[1200px] md:gap-5 lg:gap-10 xl:gap-20",
    containedwithimageonright:
      "mx-auto max-w-[1200px] md:gap-5 lg:gap-10 xl:gap-20",
    fullwidthwithimageonleft: "md:-mx-5 lg:-mx-8 2xl:-mx-24",
    fullwidthwithimageonright: "md:-mx-5 lg:-mx-8 2xl:-mx-24"
  };

  const imageColClassNames: { [key: string]: string } = {
    containedwithimageonleft:
      "flex items-center md:col-span-2 md:col-start-1 md:col-end-2",
    containedwithimageonright:
      "flex items-center md:col-span-2 md:col-start-2 md:col-end-3",
    fullwidthwithimageonleft:
      "-mx-5 order-1 md:mx-0 md:col-span-2 md:col-start-1 md:col-end-2",
    fullwidthwithimageonright:
      "-mx-5 order-2 md:mx-0 md:col-span-2 md:col-start-2 md:col-end-3"
  };

  const textColClassNames: { [key: string]: string } = {
    containedwithimageonleft: "py-6 md:col-span-2 md:col-start-2 md:col-end-3",
    containedwithimageonright: "py-6 md:col-span-2 md:col-start-1 md:col-end-2",
    fullwidthwithimageonleft:
      "py-12 order-2 w-full md:col-span-2 md:col-start-2 md:col-end-3 md:max-w-[696px] md:justify-self-start md:px-5 lg:px-8 2xl:px-24",
    fullwidthwithimageonright:
      "py-12 order-1 w-full md:col-span-2 md:col-start-1 md:col-end-2 md:max-w-[696px] md:justify-self-end md:px-5 lg:px-8 2xl:px-24"
  };

  const headingClassName =
    headingClassNames[orientation ? orientation : defaultOrientation];
  const figureClassName =
    figureClassNames[orientation ? orientation : defaultOrientation];
  const imageClassName =
    imageClassNames[orientation ? orientation : defaultOrientation];
  const imageLayout =
    imageLayouts[orientation ? orientation : defaultOrientation];
  const colClassName =
    colClassNames[orientation ? orientation : defaultOrientation];
  const imageColClassName =
    imageColClassNames[orientation ? orientation : defaultOrientation];
  const textColClassName =
    textColClassNames[orientation ? orientation : defaultOrientation];

  const renderItems = (items: Service[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          href={contentfulEntryUrl(item)}
          className="pr-button pr-button-sm pr-button-light-gray"
          key={index}
        >
          {item.previewTitle || item.title}
        </SmartLink>
      );
    });
  };

  return (
    <div>
      <ProductContainer
        fullWidth={
          orientation == "fullwidthwithimageonleft" ||
          orientation == "fullwidthwithimageonright"
        }
      >
        <div
          className={`grid content-center md:grid-flow-col-dense md:grid-cols-2 ${colClassName}`}
        >
          {image && !video && (
            <div
              className={`col-span-full hidden md:block ${imageColClassName}`}
            >
              <figure className={`relative w-full ${figureClassName}`}>
                <ContentfulImage
                  image={image}
                  transforms={
                    orientation == "fullwidthwithimageonleft" ||
                    orientation == "fullwidthwithimageonright"
                      ? fullWidthImageTransforms
                      : containedImageTransforms
                  }
                  layout={imageLayout}
                  className={imageClassName}
                />
              </figure>
            </div>
          )}
          {video && (
            <div
              className={`col-span-full flex items-center ${imageColClassName}`}
            >
              <figure className={`relative w-full`}>
                <div className="overflow-hidden rounded-2xl md:rounded-3xl">
                  <PlyrVideo video={video} />
                </div>
              </figure>
            </div>
          )}
          <div
            className={`col-span-full flex flex-col justify-center ${textColClassName}`}
          >
            {primaryIcon && (
              <figure className="relative mx-auto size-10 lg:size-16 xl:size-[90px]">
                <ContentfulImage
                  image={primaryIcon}
                  layout="fill"
                  className="object-contain"
                />
              </figure>
            )}

            {eyebrow && (
              <p
                className={`type-pr-theta md:type-pr-eta text-pr-dark-gray-400`}
              >
                {eyebrow}
              </p>
            )}

            <div
              className={`space-y-2 lg:space-y-4 ${
                eyebrow ? "mt-1.5 lg:mt-3" : ""
              } ${primaryIcon ? "mt-11 xl:mt-20" : ""}`}
            >
              <h2 className={headingClassName}>{heading}</h2>
              {image && !video && (
                <div className={`col-span-full md:hidden ${imageColClassName}`}>
                  <figure className={`relative w-full ${figureClassName}`}>
                    <ContentfulImage
                      image={image}
                      transforms={
                        orientation == "fullwidthwithimageonleft" ||
                        orientation == "fullwidthwithimageonright"
                          ? fullWidthImageTransforms
                          : containedImageTransforms
                      }
                      layout={imageLayout}
                      className={imageClassName}
                    />
                  </figure>
                </div>
              )}
              {text && (
                <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
                  <RichText text={text} />
                </div>
              )}
            </div>

            {secondaryIcon && secondaryText && (
              <div className="mt-4 flex items-center gap-4 lg:mt-5">
                <figure className="relative size-16 shrink-0 lg:h-[88px] lg:w-28">
                  <ContentfulImage
                    image={secondaryIcon}
                    layout="fill"
                    className="object-contain"
                  />
                </figure>
                <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
                  <RichText text={secondaryText} />
                </div>
              </div>
            )}

            {ctaUrl && ctaTitle && (
              <div className={`${ctaStyle == "primary" ? "mt-8" : "mt-1"}`}>
                {ctaStyle == "primary" ? (
                  <SmartLink
                    href={ctaUrl}
                    className={"pr-button pr-button-lg pr-button-green"}
                    ctaId={ctaId}
                  >
                    {ctaTitle}
                  </SmartLink>
                ) : (
                  <SmartLink
                    href={ctaUrl}
                    className="link-underline-animated-sm inline-flex items-center gap-3 text-pr-red-600"
                    ctaId={ctaId}
                  >
                    <span className="text-pr-xl">{ctaTitle}</span>
                    <svg
                      width="39"
                      height="16"
                      viewBox="0 0 39 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.7071 8.70711C39.0976 8.31658 39.0976 7.68342 38.7071 7.29289L32.3431 0.928932C31.9526 0.538408 31.3195 0.538408 30.9289 0.928932C30.5384 1.31946 30.5384 1.95262 30.9289 2.34315L36.5858 8L30.9289 13.6569C30.5384 14.0474 30.5384 14.6805 30.9289 15.0711C31.3195 15.4616 31.9526 15.4616 32.3431 15.0711L38.7071 8.70711ZM0 9H38V7H0V9Z"
                        fill="#F05843"
                      />
                    </svg>
                  </SmartLink>
                )}
              </div>
            )}

            {items.length > 0 && (
              <div className="mt-4 space-y-2.5 lg:mt-[60px] lg:space-y-4">
                {servicesLabel && (
                  <p className="text-center text-pr-xs uppercase md:text-left lg:text-pr-base">
                    {servicesLabel}
                  </p>
                )}
                <div className=" flex flex-wrap gap-1">
                  {renderItems(items)}
                </div>
              </div>
            )}
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductImageWithTextBlock;
