import * as React from "react";
import { Service } from "@src/lib/services/server/contentful";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { ProductContainer, SmartLink, ContentfulImage } from "@src/components";
import Arrow from "@/img/icons/arrow.svg";

export type ProductServicesBlockProps = {
  heading?: string | null;
  ctaTitle?: string | null;
  ctaUrl?: string | null;
  items: Service[];
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductServicesBlock({
  heading,
  ctaTitle,
  ctaUrl,
  items
}: ProductServicesBlockProps) {
  const dimensions = {
    width: 628,
    height: 442
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  const renderItems = (items: Service[]) => {
    return items.map((item, index) => {
      return (
        <SmartLink
          href={contentfulEntryUrl(item)}
          className="group grid grid-cols-3 overflow-hidden rounded-lg bg-pr-yellow-200 transition hover:drop-shadow-md focus:drop-shadow-md md:rounded-3xl"
          key={index}
        >
          <div className="col-span-1">
            <figure className="relative aspect-[628/442] size-full">
              <ContentfulImage
                image={item.previewImage || item.leadImage}
                transforms={transforms}
                layout="fill"
                className="object-cover"
              />
            </figure>
          </div>
          <div className="col-span-2 flex items-center px-2.5 py-5 md:px-12 md:py-16">
            <div className="space-y-1 md:space-y-3">
              <h3 className="type-pr-epsilon link-underline-animated-sm md:link-underline-animated-lg text-pr-red-600">
                {item.previewTitle || item.title}
              </h3>
              <p className="text-pr-xs md:text-pr-xl">
                {item.previewDescription || item.description}
              </p>
            </div>
          </div>
        </SmartLink>
      );
    });
  };

  return (
    <div>
      <ProductContainer>
        <div className="mx-auto max-w-[940px] space-y-6 lg:space-y-8">
          <div className="flex flex-col items-start gap-3 md:flex-row md:flex-wrap md:items-end md:gap-7">
            {heading && (
              <h2 className="type-pr-beta">{returnLineBreaks(heading)}</h2>
            )}
            {ctaTitle && ctaUrl && (
              <SmartLink
                href={ctaUrl}
                className="link-underline-animated-sm inline-flex items-center gap-3 text-pr-red-600 md:mb-1.5 lg:mb-3"
              >
                <span className="text-pr-xl">{ctaTitle}</span>
                <Arrow className="h-3 w-5 fill-current" />
              </SmartLink>
            )}
          </div>

          <div className="grid gap-2.5 md:gap-5">{renderItems(items)}</div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductServicesBlock;
