import * as React from "react";
import Image from "next/image";

import { ImageCarouselGroup } from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";

import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { ProductContainer, RichText } from "@src/components";

import Arrow from "@/img/icons/arrow.svg";

export type HolidayImageCarouselProps = {
  id?: string | null;
  items: ImageCarouselGroup[];
};

const HolidayImageCarousel = ({
  id = "",
  items
}: HolidayImageCarouselProps) => {
  const paginationClassName: string = `pagination-${id as string}`;
  const prevClassName: string = `prev-${id as string}`;
  const nextClassName: string = `next-${id as string}`;

  const renderItems = (items: ImageCarouselGroup[]) => {
    return items.map((item, index) => {
      const title = item.imageTitle;
      const image = item.image?.url;
      const caption = item.caption?.json as Document | undefined;
      if (!image) return null;

      return (
        <SwiperSlide key={index}>
          <div>
            <figure>
              {title && (
                <h3 className="mb-4 text-center text-4xl font-bold text-ada-salmon-500 md:text-6xl">
                  {title}
                </h3>
              )}
              <Image
                src={image}
                priority={true}
                width={900}
                height={400}
                className="rounded-2xl object-contain md:rounded-3xl"
              />
              {caption && (
                <figcaption className="type-pr-eta mt-3 text-pr-dark-gray-500">
                  <RichText text={caption} />
                </figcaption>
              )}
            </figure>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="my-4 md:my-8 lg:my-16">
      <ProductContainer>
        <div className="mx-auto max-w-[800px]">
          <div className="relative">
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              spaceBetween={16}
              slidesPerView={1}
              navigation={{
                prevEl: `.${prevClassName}`,
                nextEl: `.${nextClassName}`
              }}
              pagination={{
                clickable: true,
                bulletActiveClass: "swiper-pagination-bullet-active-product",
                bulletClass: "swiper-pagination-bullet-product",
                el: `.${paginationClassName}`
              }}
            >
              {renderItems(items)}
            </Swiper>

            <div
              className={`mt-8 flex flex-wrap items-center justify-center gap-2.5 px-24 py-4 lg:px-32 lg:py-5 ${paginationClassName}`}
            ></div>
            <div className="absolute bottom-0 right-0 z-[2] flex gap-px">
              <button
                className={`flex items-center justify-center rounded-l-full bg-pr-red-500 px-2.5 py-3.5 text-white transition disabled:bg-pr-red-100 lg:px-4 lg:py-5 ${prevClassName}`}
              >
                <Arrow className="h-3 w-5 rotate-180 fill-current" />
              </button>
              <button
                className={`flex items-center justify-center rounded-r-full bg-pr-red-500 px-2.5 py-3.5 text-white transition disabled:bg-pr-red-100 lg:px-4 lg:py-5 ${nextClassName}`}
              >
                <Arrow className="h-3 w-5 fill-current" />
              </button>
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
};

export { HolidayImageCarousel };
