import * as React from "react";
import {
  CaseStudy,
  Partner,
  Location,
  PartnerService
} from "@src/lib/services/server/contentful";
import {
  contentfulEntryUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import { SmartLink, ContentfulImage } from "@src/components";
import { filterNull } from "@src/lib/utils";

export type ProductCardGridProps = {
  entry: CaseStudy | Partner;
};

const dimensions = {
  width: 2120,
  height: 1376
};

const transforms: ContentfulImageUrlTransforms = {
  fit: "fill" as const,
  focus: "center" as const,
  format: "webp" as const,
  quality: 50,
  width: dimensions.width,
  height: dimensions.height
};

// max is max length to look for and len is length of string to be shown
// @TODO: truncate on full word?
// const truncate = (str: string, max: number, len: number) => {
//   return str.length > max ? str.substring(0, len) + "..." : str;
// };

const renderItems = (items: Location[] | PartnerService[]) => {
  return items.map((item, index) => {
    return (
      <span key={index} className="inline-block">
        {item.title}
        {index < items.length - 1 ? "," : ""}
      </span>
    );
  });
};

export function ProductCardGrid({ entry }: ProductCardGridProps) {
  // console.log("entry in ProductCardGrid: ", entry);
  const locations =
    entry.__typename == "Partner"
      ? filterNull(entry.locationsCollection?.items)
      : null;
  const services =
    entry.__typename == "Partner"
      ? filterNull(entry.servicesCollection?.items)
      : null;

  return (
    <div className="flex">
      <SmartLink
        className="group mx-auto flex size-full flex-col overflow-hidden rounded-2xl bg-pr-yellow-200 transition hover:drop-shadow-md focus:drop-shadow-md md:max-w-[440px] md:rounded-3xl"
        href={
          entry.__typename == "CaseStudy"
            ? contentfulEntryUrl(entry)
            : (entry.ctaUrl as string)
        }
      >
        {entry.leadImage && (
          <figure className="relative aspect-[2120/1376]">
            <ContentfulImage
              image={
                entry.__typename == "CaseStudy"
                  ? entry.previewImage || entry.leadImage
                  : entry.leadImage
              }
              transforms={transforms}
              layout="fill"
              className="object-cover"
              priority={true}
            />
          </figure>
        )}
        <div className="grow space-y-2 px-6 pb-8 pt-5 md:space-y-3 md:px-9 md:pb-10 md:pt-8">
          <h3 className="type-pr-eta md:type-pr-theta link-underline-animated-sm text-pr-red-600">
            {entry.__typename == "CaseStudy"
              ? entry.previewTitle || entry.title
              : entry.title}
          </h3>

          {entry.__typename == "CaseStudy" && (
            <p className="type-pr-epsilon md:text-pr-4xl">
              {(entry.previewDescription as string) ||
                (entry.description as string)}
            </p>
          )}

          {locations && locations.length > 0 && (
            <p className="flex gap-1">
              <span className="font-bold">Locations:</span>
              <span className="flex flex-wrap gap-1 text-pr-red-600">
                {renderItems(locations)}
              </span>
            </p>
          )}

          {services && services.length > 0 && (
            <p className="flex gap-1">
              <span className="mr-1 font-bold">Services:</span>
              <span className="flex flex-wrap gap-1 text-pr-red-600">
                {renderItems(services)}
              </span>
            </p>
          )}

          {entry.__typename == "Partner" && entry.budget && (
            <p className="flex gap-1">
              <span className="mr-1 font-bold">Budget:</span>
              <span className="flex flex-wrap gap-1 text-pr-red-600">
                {entry.budget}
              </span>
            </p>
          )}
        </div>
      </SmartLink>
    </div>
  );
}

export default ProductCardGrid;
