import * as React from "react";
import { IContentfulImage } from "@src/lib/types";
import { ContentfulImage } from "@src/components";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type BylineProps = {
  media?: IContentfulImage | null;
  heading?: string | null;
  text?: string | null;
};

export function Byline({ media, heading, text }: BylineProps) {
  const dimensions = {
    width: 136,
    height: 136
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };
  return (
    <div className="flex max-w-[400px] gap-4 md:max-w-[186px] md:flex-col">
      {media && (
        <div className="shrink-0">
          <figure className="aspect-square size-[55px] overflow-hidden rounded-full md:size-[68px]">
            <ContentfulImage image={media} transforms={transforms} />
          </figure>
        </div>
      )}

      <div className="flex flex-col justify-center space-y-2 pr-5">
        <h3 className="type-eta self-start">{heading}</h3>
        {text && <p className="text-2xs text-gray-400 md:text-sm">{text}</p>}
      </div>
    </div>
  );
}

export default Byline;
